import _ from 'lodash';
import angular from 'angular';
import { DashboardRootScope } from '../../main-controller';
import { IOverviewStat } from '../overview-card-charts';
import { IBucket } from '../../../components/bucket-picker';
import {
    IEchartsWithQueryViewModel,
    IEchartsWithQueryViewModelFactory,
} from '../../../directives/charts/chart-echarts-with-query-view-model';

const module = angular.module('42.controllers.overview.card-overall-stats-chart', [
    '42.filters',
    '42.directives.charts',
    '42.components.bucket-picker',
]);
export default module;

type cardOverviewChartScope = angular.IScope & {
    model?: IEchartsWithQueryViewModel;
    stat?: IOverviewStat;
    view: { bucket?: IBucket };
    selectBucket: (bucket: IBucket) => void;
};

// This is the Overview page chart
module.directive('cardOverviewChart', [
    '$rootScope',
    'EchartsWithQueryViewModel',
    (
        $rootScope: DashboardRootScope,
        EchartsWithQueryViewModel: IEchartsWithQueryViewModelFactory,
    ): angular.IDirective<cardOverviewChartScope> => ({
        restrict: 'E',
        replace: true,
        scope: {
            stat: '=',
        },
        template: `
        <div class="card overview-chart echarts-item-single"
            ng-class="{loading: !model || model.isLoading, empty:model.isBlank, error:model.isError}">
            <header>
                <chart-echarts-title-react model="model.title"></chart-echarts-title-react>
                <bucket-picker-react select="selectBucket" selected="view.bucket"></bucket-picker-react>
            </header>
            <main>
                <div ng-if="model.isError" class="message-container">
                    <div class="message">
                        <i class="message-icon icon-attention"></i>
                        <span class="message-summary">Data could not be loaded</span>
                    </div>
                </div>
                <echarts-custom ng-if="!model.isBlank" options="model.options"></echart-custom>
            </main>
        </div>
        `,
        link(scope) {
            scope.view = {};

            scope.selectBucket = (bucket: IBucket) => {
                scope.$applyAsync(() => {
                    scope.view.bucket = bucket;
                });
            };

            const createModel = (stat: IOverviewStat, bucket: IBucket) => {
                const isBudgetMetric = (() => {
                    if (!stat.comparison?.field) return false;
                    return stat.comparison.field !== `growth_${stat.actual.field}_prev`;
                })();

                return {
                    type: 'line',
                    properties: [`calendar.${bucket.id}`, 'calendar.year'],
                    overrides: {
                        lineChart: {
                            bucket: bucket.id,
                            ...(isBudgetMetric
                                ? { timeRangeAsHeader: true, displayTitle: true, colors: ['#A5A3FF', '#5DA5DA'] }
                                : {}),
                        },
                    },
                    metrics: (function () {
                        const ty = stat.actual.field;
                        const ly = stat.comparison?.field;
                        return [ty, ly].filter(Boolean);
                    })(),
                };
            };

            const updateModel = _.debounce(() => {
                if (!scope.stat || !scope.view.bucket) return;
                const query = _.cloneDeep($rootScope.query);
                const model = createModel(scope.stat, scope.view.bucket);
                scope.model = new EchartsWithQueryViewModel(model, query);
                return;
            }, 100);

            scope.$watch('stat', updateModel);
            return scope.$watch('view.bucket', updateModel);
        },
    }),
]);
