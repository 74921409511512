import './list-custom-header.scss';

export interface IListSearchBoxDirectiveScope extends angular.IScope {
    search: { id: string };
    onSearch: (value: string) => void;
}

export const ListSearchBoxDirective = () => [
    function ListSearchBox(): angular.IDirective<IListSearchBoxDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                onSearch: '=',
            },
            replace: true,
            template: `
                <div class="list-menu-search-box filter-input-search"
                    ng-class="{'filtered': search.id !== ''}">
                    <i class="icon-clear-filter icon-cancel-circled"
                        ng-click="search.id = ''"
                        ng-show="search.id !== ''">
                    </i>
                    <input
                        type="text"
                        spellcheck="false"
                        placeholder="search"
                        ng-model="search.id"
                    >
                    <i class="icon-search"></i>
                </div>
            `,
            link: function ListSearchBoxLink($scope) {
                $scope.search = {
                    id: '',
                };

                $scope.$watch('search.id', (value: string) => {
                    $scope.onSearch(value);
                });
            },
        };
    },
];

export interface IListCustomHeaderOptions {
    onSearch: (value: string) => void;
    reset: () => void;
    selectAll: () => void;
    itemLabel?: string;
    mode?:
        | {
              label: string;
              message: string;
              value: boolean;
              onClick: () => void;
          }
        | undefined;
}
interface IListCustomHeaderDirectiveScope<T> extends angular.IScope {
    model: { available: T[]; selected: T[] };
    message: string;
    options: IListCustomHeaderOptions;
}

export const ListCustomHeaderDirective = () => [
    function ListCustomHeader<T>(): angular.IDirective<IListCustomHeaderDirectiveScope<T>> {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                toggle: '=',
                options: '=',
            },
            replace: true,
            template: `
                <div class="list-custom-header">
                    <list-search-box
                        ng-if="model.available.length > 0 && toggle.isActive"
                        on-search="options.onSearch">
                    </list-search-box>
                    <div class="list-header-selection-actions" ng-if="toggle.isActive">
                        <div class="list-header-selection-actions-left">
                            <div class="list-header-selection-action-select-all"
                                ng-class="{'with-reset': model.available.length > 0 && model.selected.length !== model.available.length && model.selected.length > 0}">
                                <div
                                    class="list-header-selection-action select-all"
                                    ng-if="model.available.length > 0 && model.selected.length !== model.available.length"
                                    ng-click="options.selectAll()">
                                    select all
                                </div>
                            </div>
                            <div
                                class="list-header-selection-action"
                                ng-if="model.selected.length > 0"
                                ng-click="options.reset()">
                                reset
                            </div>
                        </div>
                        <div class="list-header-selection-actions-right">
                            <div class="list-header-selection-description" ng-if="!options.mode">
                                {{ message }}
                            </div>
                            <div class="list-header-selection-mode-toggle"
                                ng-if="options.mode"
                                ng-class="{active: options.mode.value}"
                                ng-click="options.mode.onClick()">
                                <div class="mode-label">{{ options.mode.label }}</div>
                                <tooltip ng-if="options.mode.value" messages="[options.mode.message]"></tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            link: function ListCustomHeaderLink($scope) {
                $scope.$watch('model.selected', (selected: T[] | undefined): void => {
                    if (selected === undefined || selected.length === 0) {
                        $scope.message = '';
                        return;
                    }

                    const numberOfAvailable = $scope.model.available.length;
                    $scope.message =
                        numberOfAvailable === selected.length
                            ? `selected all ${selected.length} ${$scope.options.itemLabel ?? 'items'}`
                            : `selected ${selected.length} of ${numberOfAvailable} ${
                                  $scope.options.itemLabel ?? 'items'
                              }`;
                });
            },
        };
    },
];

const listCustomHeaderModule = angular
    .module('42.components.list-search-box', [])
    .directive('listSearchBox', ListSearchBoxDirective())
    .directive('listCustomHeader', ListCustomHeaderDirective());

export default listCustomHeaderModule;
