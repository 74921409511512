import { IHierarchyService } from '../../../modules/hierarchy';
import { ICustomer, ICustomerAPI } from '../customer.types';

export const CustomerDetailsControllerInstance = () => [
    '$q',
    '$rootScope',
    '$scope',
    '$route',
    'promiseTracker',
    'Hierarchy',
    'CustomerAPI',
    function CustomerDetailsController(
        $q: angular.IQService,
        $rootScope: angular.IRootScopeService,
        $scope: angular.IScope & { customer: ICustomer; [key: string]: any },
        $route: angular.route.IRouteParamsService,
        promiseTracker: angular.promisetracker.PromiseTrackerService,
        Hierarchy: IHierarchyService,
        CustomerAPI: ICustomerAPI,
    ) {
        $scope.customerDetailsPromiseTracker = promiseTracker();
        const customerId = $route.current.params.customerId;

        $scope.selectPreviousTransaction = () => $scope.select($scope.transactionIndex - 1);
        $scope.selectNextTransaction = () => $scope.select($scope.transactionIndex + 1);

        $scope.select = (index: string) => {
            const newIndex = parseInt(index, 10);
            const transactionsList = $scope.customer?.transactions || [];
            const transaction = transactionsList[newIndex];

            if (!transaction) {
                return;
            }

            $scope.transaction = transaction;
            $scope.transactionIndex = newIndex;
        };

        const loadPage = () => {
            const customerAPIPromise = new CustomerAPI();

            return customerAPIPromise.then(api => {
                const refreshItemProperties = () => {
                    return Hierarchy.fetch().then(hierarchy => {
                        const result = hierarchy.items.filter(f =>
                            f.column !== undefined
                                ? ['id', 'name', 'company', 'type', 'pattern'].indexOf(f.column) > -1
                                : false,
                        );

                        $scope.itemProperties = result.slice(0, 4);
                    });
                };

                return $q.all([refreshItemProperties(), api.getDetails(customerId)]).then(([_, customer]) => {
                    $scope.customer = customer;
                    $scope.select(0);
                });
            });
        };

        $scope.customerDetailsPromiseTracker.addPromise(loadPage());
    },
];
