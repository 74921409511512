import _ from 'lodash'
import moment from 'moment'
import { isObject } from '../lib/utils'

module = angular.module '42.filters.misc', []
export default module

module.filter 'image', ($filter) -> (value) ->
    return value if typeof value isnt 'string'
    return $filter('linky')(value)

module.filter 'limit', -> (array, lowerBound, upperBound) ->
    return [] if not array

    if arguments.length is 2
        [lowerBound, upperBound] = do ->
            return [lowerBound.left, lowerBound.right] if _.isObject lowerBound
            return [0, lowerBound]

    lowerBound = Math.max(0,              (parseInt lowerBound)) or 0
    upperBound = Math.min(array.length-1, (parseInt upperBound)) or Infinity

    return array[lowerBound..upperBound]


module.service 'FilterArgParsers', ->
    bool: (value) ->
        return value if _.isBoolean(value)
        return value.toLowerCase() is "true" if _.isString(value)
        return false
    int: (value) ->
        return null if _.isUndefined(value) or _.isNull(value)
        value = parseInt(value)
        return null if _.isNaN(value)
        return value


module.filter 'percent', ($filter, FilterArgParsers, CONFIG) -> (num, showZeros = null, showNegative = null, decimals = null) ->
    percentages = CONFIG.defaults?.abbreviation?.percentages ? 2
    decimals = percentages if _.isNil(decimals)
    [showZeros, showNegative] = [showZeros, showNegative].map(FilterArgParsers.bool)
    decimals = FilterArgParsers.int(decimals)
    decimals = 2 if _.isNull(decimals)
    return '' if _.isNil(num)
    num = (parseFloat(num) * 100).toFixed(decimals)
    num = $filter('number')(num, decimals)
    num = num.replace(/\.00$/, '') if not showZeros
    num = num.replace(/^-/, '') if not showNegative
    return "" if not num
    return "#{num}%"


module.directive 'percent', ->
    restrict: 'A'
    link: (scope, element, attributes) ->
        $element = $(element)
        $element.addClass 'percent'
        isWrapped = false
        attributes.$observe 'percent', (percent) ->
            percent = parseFloat(percent)
            $element.removeClass('percent-positive')
            $element.removeClass('percent-negative')
            $element.addClass do ->
                return 'percent-positive' if percent > 0
                return 'percent-negative' if percent < 0
            if percent is 0
                return if not isWrapped
                $element.contents().unwrap()
                isWrapped = false
            else
                return if isWrapped
                $element.contents().wrap("<span class='perchevron'></span>")
                isWrapped = true


module.directive 'focus', ->
    restrict: 'A'
    link: (scope, element, attributes) ->
        $element = $(element)
        parse = (x) -> x is 'true'
        attributes.$observe 'focus', (shouldFocus) ->
            shouldFocus = parse(shouldFocus)
            method = if shouldFocus then 'focus' else 'blur'
            $element[method]()


module.filter 'diff', ($filter) -> (num) ->
    num = (num or '').toString().replace('-', '')
    return $filter('number')(num)


module.service 'NumberAbbreviator', ->

    # Taken from:
    # https://github.com/mout/mout/tree/v0.3.0/src/number

    _defaultDict =
        thousand: 'k'
        million:  'm'
        billion:  'b'

    enforcePrecision = (val, nDecimalDigits) ->
        pow = Math.pow(10, nDecimalDigits)
        return +(Math.round(val * pow) / pow).toFixed(nDecimalDigits)

    abbreviateNumber = (val, nDecimals, dict) ->
        nDecimals = (if nDecimals? then nDecimals else 1)
        dict = dict or _defaultDict
        isNegative = not ((val-0.001) < Math.abs(val) < (val+0.001))
        val = Math.abs(val)
        prefix = if isNegative then "-" else ""
        return "#{prefix}#{val.toFixed(nDecimals)}" if val < 1000
        val = enforcePrecision(val, nDecimals)
        str = undefined
        mod = undefined
        if val < 1000000
            mod = enforcePrecision(val / 1000, nDecimals)
            # might overflow to next scale during rounding
            str = (if mod < 1000 then mod + dict.thousand else 1 + dict.million)
        else if val < 1000000000
            mod = enforcePrecision(val / 1000000, nDecimals)
            str = (if mod < 1000 then mod + dict.million else 1 + dict.billion)
        else
            str = enforcePrecision(val / 1000000000, nDecimals) + dict.billion
        return "#{prefix}#{str}"

    return format:abbreviateNumber


module.filter 'metric', ($filter) ->
    getCellFilter = (metric) ->
        metric = metric.cellFilter if isObject(metric)
        return metric if typeof metric is 'string'
        return null
    return (value, metric) ->
        cellFilter = getCellFilter(metric)
        [filter, args...] = cellFilter.split(':')
        [filter, args] = ['number', []] if not ['number', 'percent', 'money', 'duration', 'numberAbbreviated'].includes(filter)
        return $filter(filter)(value, args...)


module.filter 'multiplier', (NumberAbbreviator) -> (value, nDecimalDigits) ->
    formattedNumber =  NumberAbbreviator.format(value, nDecimalDigits)
    return "#{formattedNumber}x"

module.filter 'numberAbbreviated', (NumberAbbreviator) -> (value, nDecimalDigits) ->
    return NumberAbbreviator.format(value, nDecimalDigits)


module.filter 'duration', () -> (value, inputFormat, outputFormat) ->
    return '' if _.isNil(value)
    outputFormat ?= 'seconds'
    timeFormat =
        minutes:
            formatString: 'm',
            unit: 'min'
        seconds:
            formatString: 'mm:ss'
            unit: 'min'
        hours:
            formatString: 'HH:mm:ss'
            unit: 'hr'

    { formatString, unit } = timeFormat[outputFormat] or timeFormat.minutes
    timeDuration = moment.utc(moment.duration(value, inputFormat).asMilliseconds()).format(formatString)

    return "#{timeDuration} #{unit}"


module.filter 'money', ($filter, $rootScope, NumberAbbreviator, CONFIG) -> (text, digits, enabled) ->
    return '' if _.isNil(text) or _.isNaN(parseFloat(text))
    currencySymbol = $rootScope.currencyModel.selected?.symbol or '$'
    value = parseFloat(text)
    options = do ->
        config = _.extend {digits: 2, enabled: true}, CONFIG.defaults?.abbreviation
        config.digits  = digits  if not _.isUndefined(digits)
        config.enabled = enabled if not _.isUndefined(enabled)
        return config
    sign = if value < 0 then "-" else ""
    value = Math.abs(value)
    digits = do ->
        return 0 if value < 1000 and options.enabled
        return options.digits
    text = do ->
        return NumberAbbreviator.format(value, digits) if options.enabled and value >= 1000
        $filter('currency')(value.toFixed(digits))
            .replace('$', '')
            .replace(/[()]/g, '')
            .replace(/\.00?$/, '')
    sign = "" if text is "0"
    return "#{currencySymbol}#{sign}#{text}"
