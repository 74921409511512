import _ from 'lodash';
import { ConfigAPI } from '../../lib/config-api';
import { CachedService } from '../../lib/model/model-service-cached';
import { getPropertyDefinitions } from '../../lib/config-hierarchy';
import { DatabaseDescriptorsService } from '../../lib/config-database-descriptors';

export interface IHierarchyDefinition {
    id: string;
    label: string;
}

// These are the properties available to the dashboard
export type IHierarchyService = ReturnType<typeof HierarchyService>;
export const HierarchyService = () => {
    const cache = CachedService(() => {
        return Promise.all([
            ConfigAPI.get().then(api => Promise.all([api.organization.get(), api.user.getInternal()])),
            DatabaseDescriptorsService.fetch(),
        ]).then(([[orgConfig, userConfig], descriptors]) => {
            return [orgConfig, userConfig, descriptors];
        });
    });
    void cache.init();
    return {
        fetch() {
            return cache.fetch().then(([orgConfig, userConfig, descriptors]) => {
                return getPropertyDefinitions(userConfig, orgConfig, descriptors);
            });
        },
    };
};

const module = angular.module('42.modules.hierarchy', []);
export default module;

module.service('Hierarchy', [
    '$q',
    ($q: angular.IQService) => {
        const instance = HierarchyService();
        const fetch = () => $q.when(instance.fetch());
        return { fetch };
    },
]);
