import _ from 'lodash'
import { ResizeObserver } from '@juggle/resize-observer'
import Highcharts from './highcharts/index.coffee'
import {
    ChartPageRouteConfigFactory,
    StoreControllerFactory,
    ChartPageTabsFactory,
    ChartViewTabServiceFactory,
    StoreActionsPanelDirective,
    StoreMainPartDirective
} from './index'
import { TimeGroupings } from './store-chart-time-groupings'
import { logError } from '../../lib/analytics'
import { purifyText } from '../../lib/dom/html'

module = angular.module '42.controllers.store', []

module.config(ChartPageRouteConfigFactory())
module.controller('StoreController', StoreControllerFactory())
module.factory('ChartPageTabs', ChartPageTabsFactory())
module.factory('ChartViewTabService', ChartViewTabServiceFactory())
module.directive('storeActionsPanel', StoreActionsPanelDirective())
module.directive('storeMainPart', StoreMainPartDirective())


module.filter 'maximum', -> (arr, max, enable) ->
    arr?.filter (x) -> enable or x <= max

module.directive 'storeFunnelState', ->
    restrict: 'E'
    scope:
        model: '='
        label: '='
    replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb">
        <section class="funnel-state">
            <header>
                <h1>Selected Filters</h1>
                <button class="reset"
                    ng-if="model.funnel.nodes.length > 0"
                    ng-click="model.resetFilters()">reset
                </button>
            </header>
            <ul class="ui-pellets">
                <li class="funnel-node">
                    <span class="ui-pellet active disabled">
                        <span class="ui-pellet-property">Selected Group</span>
                        <span class="ui-pellet-value">{{ label }}</span>
                    </span>
                </li>
                <li class="funnel-node" ng-repeat="node in model.funnel.nodes"
                    ng-click="model.applyFilters(node)"
                    ng-class="{'funnel-node-selected': model.funnel.selected == node}">
                    <div class="ui-pellet" ng-class="{active: model.funnel.selected == node}">
                        <span class="ui-pellet-property">{{ node.label }}</span>
                        <span class="ui-pellet-value">{{ node.choice }}</span>
                    </div>
                </li>
            </ul>
        </section>
    </article>
    """


module.directive 'storeTextRow', ->
    restrict: 'E'
    scope:
        model: '='
        toggle: '='
    replace: true
    template: """
        <article class="store-text-row">
            <p class="natural-language">
                Showing the
                <span class="order">{{ model.selected.sortOrder.label === 'asc' ? 'Top' : 'Bottom' }}</span>
                <span class="limit"> {{ model.selected.limitBy }}</span>
                <span class="grouping"> {{ model.selected.grouping.plural }}</span>
                by
                <span class="metric"> {{ model.selected.metric.label }}</span>
            </p>

            <section class="actions" ng-class="{'full': toggle.isActive}">
                <div class="default-actions">
                    <button class="chart-export">
                        <i class="icon-export">export chart</i>
                    </button>

                    <button class="stacked-area" ng-class="{selected:model.selected.stacking}"
                        ng-click="model.selected.stacking = true"
                        ng-if="model.selected.timeGrouping.chartType == 'timeseries'">
                        <i class="icon-chart-area">Stacked Area</i>
                    </button>

                    <button class="line" ng-class="{selected:!model.selected.stacking}"
                        ng-click="model.selected.stacking = false"
                        ng-if="model.selected.timeGrouping.chartType == 'timeseries'">
                        <i class="icon-chart-line">Line</i>
                    </button>
                </div>
                <button class="reversed"
                    ng-class="{'show': toggle.isActive}"
                    ng-click="toggle.close()">
                    <i class="icon-down-open">Show Panel</i>
                </button>
            </section>
        </article>
        """


module.directive 'storeHighcharts', ['HighchartsModel', 'HighchartsChartFactory', (HighchartsModel, HighchartsChartFactory) ->
    restrict: 'E'
    scope:
        view: '='
        save: '=',
    replace: true
    template: \
    """
    <article class="store-chart">
      <div class="loadable" ng-class="{'loading': view.model === null || view.model.tracker.active()}"></div>
      <main class="store-chart-container"></main>
    </article>
    """
    link: (scope, element) ->
        chartContainer = $(element).find('.store-chart-container')
        chart = new HighchartsChartFactory(chartContainer, 'barchart')

        resizeObserver = new ResizeObserver (_.debounce((-> chart.reflow()), 50))
        resizeObserver.observe(element[0])

        unWatchSelectedChart = null
        unWatchSelectedParams = null

        scope.$watch 'view', ->
            unWatchSelectedChart?()
            unWatchSelectedParams?()
            unWatchSelectedParams = scope.$watch 'view.params', ->
                unWatchSelectedChart?()
                return if not scope.view?.params
                { groupBy, metrics } = scope.view.params
                scope.view.model = new HighchartsModel(groupBy, chart, scope.view.model, metrics)
                unWatchSelectedChart = scope.$watch('view.model.selected', ((newModel, oldModel) ->
                    scope.view.model?.refreshQuery()
                    scope.save()
                ), true)

        scope.$on '$destroy', ->
            chart.cleanup()
            resizeObserver.disconnect()
]


module.factory 'HighchartsChartFactory', ['Utils', 'HighchartsConfig', (Utils, HighchartsConfig) ->
    return (element, type) ->  # element is the chart container
        chart = null
        latestQueryHash = null

        refresh: (query, model) ->
            latestQueryHash = Utils.object.hash(query)
            chart = null
            HighchartsConfig.fetch(query, model)
            .then (config) ->
                return if Utils.object.hash(query) isnt latestQueryHash
                config.chart.renderTo = $(element).get(0)
                chart = new Highcharts.Chart(config)
                $('.highcharts-button').remove()
                exportButton = $('.chart-export').off('click')
                exportButton.on('click', -> chart.exportChartLocal(type:'image/png'))
                return
            .catch (error) ->
                logError(error)
                chart?.destroy()
                chart = null
                return

        reflow: ->
            chart?.reflow()

        cleanup: ->
            chart?.destroy()
            $('.chart-export').off('click')
            $(element).empty()
]


module.factory 'HighchartsModel', ($rootScope, promiseTracker, Utils, CONFIG) -> return class HighchartsModel

    constructor: (all, chart, previousModel, availableMetrics) ->
        @timeseriesAvailableMetrics = do ->
            return availableMetrics.flatMap (metric) ->
                return [] if metric.field.startsWith('growth')
                return [] if not metric
                id:    metric.field
                type:  metric.cellFilter
                group: metric.category ? 'Uncategorized'
                name:  metric.headerName
                label: do ->
                    return "#{metric.headerGroup} - #{metric.headerName}" if metric.headerGroup and metric.headerName
                    return metric.headerGroup or metric.headerName

        @nonTimeseriesAvailableMetrics = do ->
            metrics = CONFIG.defaults?.stores?.kpis or CONFIG.views?.stores?.kpis
            metrics ?= do ->
                defaults = CONFIG.views?.sales?.kpis or []
                return defaults.flatMap (x) -> [
                    x,
                    "growth_#{x}_prev"
                ]
            metrics = _.uniq(metrics)

            return _.compact metrics.map (x) ->
                metric = _.find availableMetrics, {field: x}
                return null if not metric
                id:    metric.field
                type:  metric.cellFilter
                group: metric.category ? 'Uncategorized'
                name:  metric.headerName
                label: do ->
                    return "#{metric.headerGroup} - #{metric.headerName}" if metric.headerGroup and metric.headerName
                    return metric.headerGroup or metric.headerName

        @available =
            grouping: Utils.copy(all)
            limitBy: [5, 10, 20, 50, 100, 1000]
            sortOrder: [
                {id: -1, label: 'DESC'}
                {id:  1, label: 'ASC'}
            ]
            timeGrouping: TimeGroupings
            stacking: [false, true]
            select: (key, defaultIndex = 0) ->
                defaultValue = do =>
                    return defaultIndex if not _.isNumber(defaultIndex)
                    return @[key][defaultIndex]
                return defaultValue if not previousModel
                prev = previousModel?.selected?[key]
                return prev if typeof prev is 'boolean'
                result = _.find @[key], (x) ->
                    return x is prev if _.isNumber(x)
                    return x.id is prev?.id
                return result or defaultValue

        timeGrouping = @available.select('timeGrouping')

        @available.metric = do =>
            return _.cloneDeep(@nonTimeseriesAvailableMetrics) if timeGrouping.chartType is 'barchart'
            return _.cloneDeep(@timeseriesAvailableMetrics)

        @funnel = {nodes: previousModel?.funnel?.nodes ? [], selected: previousModel?.funnel?.selected ? {}}
        @selected =
            filters: previousModel?.selected?.filters or {}
            grouping: do =>
                selectedGrouping = @available.select 'grouping', do =>
                    result = _.find @available.grouping, (x) -> x.id is 'stores.name'
                    return result or @available.grouping[0]

                return selectedGrouping if @funnel.nodes.length is 0
                lastFunnelNode = @funnel.nodes[@funnel.nodes.length - 1]
                return lastFunnelNode if selectedGrouping.id isnt lastFunnelNode.id
                return selectedGrouping
            limitBy:      @available.select('limitBy', 2)
            metric:       @available.select('metric') # metric to sort by for bar charts, to display for timeseries
            sortOrder:    @available.select('sortOrder')
            timeGrouping: timeGrouping
            stacking:     @available.select('stacking', true)
            properties: do =>
                funnelSelectedNodes = @funnel.nodes.map (x) -> x.id
                return @available.grouping.filter (property) -> not funnelSelectedNodes.includes(property.id)

        @groupByNext() if not @selected.properties.find((p) => p.id is @selected.grouping.id)

        @tracker = promiseTracker()
        @chart = chart
        @chart.labelFormatterOn = false
        @visible = @available.metric.map (x, index) -> index < 2
        Highcharts.wrap Highcharts.Series.prototype, 'setVisible', do =>
            model = this
            return (proceed) ->
                proceed.apply(@, Array.prototype.slice.call(arguments, 1))
                model.visible = @chart.series.map (x) -> x.visible
                if model.selected.timeGrouping.chartType is 'barchart' and not model.selected.stacking
                    # This updates the y-axis labels based on the metric selection
                    @chart.yAxis.forEach (axis) ->
                        series = axis.series.filter((x) -> x.visible)
                        return if series.length is 0
                        metrics = series.map (x) -> x.options.metric
                        metricGroups = _.groupBy metrics, (x) -> x.group
                        axis.update title: text: Object.keys(metricGroups).map((key) ->
                            "#{key}: " + metricGroups[key].map((x) -> x.name).join(' · ')
                        ).join(', ')

    findIndexById: (array, id) ->
        return _.findIndex(array, {'id': id})

    resetFilters: ->
        @selected.filters = {}
        @selected.grouping = _.find(@available.grouping, (x) -> x.id is 'stores.name') ? @available.grouping[0]
        @funnel = {nodes: [], selected: {}}
        @selected.properties = do =>
            funnelSelectedNodes = @funnel.nodes.map (x) -> x.id
            return @available.grouping.filter (property) -> not funnelSelectedNodes.includes(property.id)
        return

    applyFilters: (selectedNode) ->
        # apply all filters only up to that node
        @selected.filters = {}
        if @funnel.selected is selectedNode
            @funnel.selected = {}
            @selected.grouping = @funnel.nodes[@funnel.nodes.length - 1]

            for node in @funnel.nodes
                @selected.filters[node.table] ?= {}
                @selected.filters[node.table][node.column] = node.choice

            @selected.properties = do =>
                funnelSelectedNodes = @funnel.nodes.map (x) -> x.id
                return @available.grouping.filter (property) -> not funnelSelectedNodes.includes(property.id)

            @groupByNext()
        else
            for node in @funnel.nodes
                if node.id is selectedNode.id
                    @funnel.selected = node
                    @selected.grouping = selectedNode
                    @selected.properties = do =>
                        selectedNodeIndex = @funnel.nodes.findIndex (n) -> node.id is n.id

                        if selectedNodeIndex is 0
                            return @available.grouping
                        else
                            selectedNodeIndex = selectedNodeIndex - 1

                        propertiesToIgnore = []
                        [0..selectedNodeIndex].forEach (i) => propertiesToIgnore.push(@funnel.nodes[i].id)
                        return @available.grouping.filter (property) -> not propertiesToIgnore.includes(property.id)
                    return
                @selected.filters[node.table] ?= {}
                @selected.filters[node.table][node.column] = node.choice
                @selected.properties = do =>
                    funnelSelectedNodes = @funnel.nodes.map (x) -> x.id
                    return @available.grouping.filter (property) -> not funnelSelectedNodes.includes(property.id)

        return

    nodeInFunnel: (node) ->
        activeFilters = @funnel.nodes
        activeFilters = @funnel.nodes[0 ... @findIndexById(@funnel.nodes, @funnel.selected.id)] if not _.isEmpty(@funnel.selected)
        return not _.isUndefined(_.find(activeFilters, {'id': node.id}))

    groupByNext: ->
        # change the groupby level to the next one not in the funnel
        groupIndex = initIndex = @findIndexById(@available.grouping, @selected.grouping.id)
        groupIndex = ++groupIndex % @available.grouping.length
        while @nodeInFunnel(@available.grouping[groupIndex]) and groupIndex isnt initIndex
            groupIndex = ++groupIndex % @available.grouping.length
        @selected.grouping = @available.grouping[groupIndex]
        @selected.properties = do =>
            funnelSelectedNodes = @funnel.nodes.map (x) -> x.id
            return @available.grouping.filter (property) -> not funnelSelectedNodes.includes(property.id)

        return

    updateTimeGrouping: (timeGrouping) ->
        @selected = {
            ...@selected,
            timeGrouping: _.cloneDeep(timeGrouping)
        }
        @_updateAvailableMetrics()
        return

    _updateAvailableMetrics: ->
        @available.metric = do =>
            return _.cloneDeep(@nonTimeseriesAvailableMetrics) if @selected.timeGrouping.chartType is 'barchart'
            return _.cloneDeep(@timeseriesAvailableMetrics)
        metricToSelect = @available.metric.find((metric) => metric.id is @selected.metric.id)
        @selected.metric = metricToSelect ? @available.metric[0]
        return

    refreshQuery: (rootQuery) =>
        selected = _.cloneDeep(@selected)
        query = _.cloneDeep(rootQuery or $rootScope.query)
        delete query.limit
        delete query.sort
        query.options = {}
        query.options.fillCalendarGaps = do ->
            return selected.timeGrouping.chartType is 'timeseries'
        query.options.metrics = do =>
            return (@available.metric.map (x) -> x.id) if selected.timeGrouping.chartType is 'barchart'
            return [@selected.metric.id]
        query.options.properties = [
            selected.grouping.id,
            ...selected.timeGrouping.property
        ]
        query.options.sort = [
            {field: selected.metric.id, order: selected.sortOrder.id, limit: selected.limitBy},
            ...selected.timeGrouping.property.map((field) -> {field, order:1})
        ]
        selectedFilters = selected.filters
        transactionsFilter = _.cloneDeep(query.filters.transactions)
        for [tableKey, columnKeys] from Object.entries(selectedFilters ? {})
            for columnKey from Object.keys(columnKeys)
                continue if tableKey is 'transactions' and columnKey is 'timestamp'
                query.filters[tableKey] ?= {$and:[]}
                columnIndex = (query.filters[tableKey]?.$and or []).findIndex (x) -> Object.keys(x)[0] is columnKey
                value = selectedFilters[tableKey][columnKey]
                if columnIndex is -1
                    column = {}
                    column[columnKey] = {$in:[value]}
                    query.filters[tableKey] ?= {}
                    query.filters[tableKey].$and ?= []
                    query.filters[tableKey].$and.push(column)
                else
                    column = query.filters[tableKey]?.$and[columnIndex]
                    column[columnKey] ?= {$in:[]}
                    column[columnKey].$in = _.union(column.$in, [value])
        query.filters.transactions = transactionsFilter
        return @tracker.addPromise(@chart.refresh(query, @))


# This is the new function to use once the db-growth query service branch is deployed
module.service 'HighchartsSeriesData', ['$q', 'Utils', 'QueryServiceAPI', ($q, Utils, QueryServiceAPI) ->

    ###* @argument {Record<string, string | number | null>[]} rows ###
    removeTotals = (rows) ->
        return rows if rows.length is 0
        propertyKeys = Object.keys(rows[0]).filter((x) -> x.startsWith('property'))
        return rows.filter (row) -> propertyKeys.every (k) -> row[k] isnt '$total'

    fetch: (model, rootQuery) ->
        {chartType} = model.selected.timeGrouping
        switch chartType
            when 'barchart' then return @fetchBarchart(model, rootQuery)
            when 'timeseries' then return @fetchTimeseries(model, rootQuery)
            else throw new Error("Unknown chart type `#{chartType}`.")

    fetchBarchart: (model, rootQuery) ->
        query = Utils.copy(rootQuery)
        query.options ?= {}
        query.options.includeTotals = false
        query.options.includeItemInformation = false
        return QueryServiceAPI()
        .then((api) -> api.query.metricsFunnel(query))
        .then((series) -> {series})
        .catch (err) ->
            logError(err)
            return {series: []}

    fetchTimeseries: (model, rootQuery) ->
        query = Utils.copy(rootQuery)
        query.options ?= {}
        query.options.fillCalendarGaps = true
        query.options.includeItemInformation = false
        delete query.sort
        delete query.limit

        return QueryServiceAPI()
        .then (api) ->

            # TODO: remove this query service call
            # NOTE: the code downstream uses this to generate the xAxis labels...
            totalQueryPromise = api.query.metricsFunnel do ->
                totalQuery = Utils.copy(query)
                totalQuery.options ?= {}
                totalQuery.options.includeTotals = false
                totalQuery.options.property = [
                    'stores.aggregate',
                    ...model.selected.timeGrouping.property
                ]
                totalQuery.options.sort = [
                    {field: 'stores.aggregate', order: model.selected.sortOrder.id},
                    ...model.selected.timeGrouping.property.map((field) -> {field, order:1})
                ]
                return totalQuery

            # Top n dimensions grouped by the selected property, sorted by the selected metric...
            seriesQueryPromise = api.query.metricsFunnel do ->
                seriesQuery = Utils.copy(query)
                seriesQuery.options ?= {}
                # NOTE: need to include totals because the backend can't sort hierarchically without it
                seriesQuery.options.includeTotals = true
                seriesQuery.options.properties = [
                    model.selected.grouping.id,
                    ...model.selected.timeGrouping.property
                ]
                seriesQuery.options.sort = [
                    {field: model.selected.metric.id, order: model.selected.sortOrder.id, limit: model.selected.limitBy},
                    ...model.selected.timeGrouping.property.map((field) -> {field, order:1})
                ]
                return seriesQuery

            return $q.all([seriesQueryPromise, totalQueryPromise])
        .then ([series, totalSeries]) ->
            totalSeries: removeTotals(totalSeries)
            series: removeTotals(series)
        .catch (err) ->
            logError(err)
            return { series: [], totalSeries: [] }
]

module.service 'HighchartsConfig', ['$filter', 'Utils', 'HighchartsSeriesData', ($filter, Utils, HighchartsSeriesData) ->
    fetch: (query, model) ->
        HighchartsSeriesData.fetch(model, query).then ({series, totalSeries}) ->
            valueFormatter = (type, x) ->
                [filter, args...] = type.split(':')
                return $filter(filter)(x, args...)

            isNotTotalRow = (row) ->
                propertyValues = _.range(row.property_count).map (i) -> row["property#{i}"]
                return not propertyValues.includes('$total')

            parseMetricValue = (x) ->
                x = parseFloat(x)
                return 0 if _.isNaN(x) or not _.isNumber(x)
                return parseFloat(x.toFixed(2))

            isTimeseries = model.selected.timeGrouping.chartType is 'timeseries'

            # Create yAxes for each type of measurement
            types = do ->
                availableMetrics = do ->
                    return [model.selected.metric] if isTimeseries
                    return model.available.metric
                return _.groupBy availableMetrics, (x) -> x.type.split(':')[0]

            typesArray = _.compact _.map types, (metrics) ->
                metricType = metrics[0].type.split(':')[0]
                return [metricType, metrics[0].type, metrics]

            yAxes = _.compact _.map typesArray, ([type, filter, metrics], index) ->
                animation: false
                metricGroup: type
                title: text: null
                labels: formatter: -> return valueFormatter(filter, @value)
                opposite: !!(index % 2)

            xAxisCategories = undefined
            combinedSeries = []

            if model.selected.timeGrouping.chartType is 'barchart'
                combinedSeries = _.map model.available.metric, (metric, index) ->
                    name: metric.label
                    colorByPoint: false
                    metric: metric
                    type: 'column'
                    data: series.filter(isNotTotalRow).map (point) ->
                        name      : point.property0
                        y         : parseMetricValue(point[metric.id])
                        drilldown : true
                        fullInfo  : point
                    yAxis: _.findIndex typesArray, ([type]) ->
                        return type is metric.type.split(':')[0]
                    dataLabels:
                        formatter: -> valueFormatter(metric.type, @y)
                    visible: do ->
                        return model.visible[index] if model.visible
                        return false


            else if model.selected.timeGrouping.chartType is 'timeseries'
                dimensionSeries = series.filter(isNotTotalRow)
                dimensionSeries = dimensionSeries.filter((x) -> Boolean(x.property0))

                createSeriesPoint = (row) ->
                    name      : model.selected.timeGrouping.pointFormatter(row)
                    y         : row[model.selected.metric.id] ? 0
                    drilldown : true
                    fullInfo  : row

                totalSeries = do ->
                    total = totalSeries.filter(isNotTotalRow)
                    total = _.sortBy(total, "calendar__timestamp")
                    total = total.map((row) -> {...createSeriesPoint(row), drilldown: false})
                    return _.uniqBy(total, 'name')

                xAxisCategories = do ->
                    return totalSeries.map((x) -> x.name)

                createSeries = (rows) ->
                    mappedPoints = rows.map (point) -> _.mapValues point, (val, key) ->
                        return val if not key.startsWith('property') or Number.isNaN(parseFloat(val))
                        return parseFloat(val)
                    # NOTE: this needs to be ordered by timestamp, exactly how the totalSeries is sorted
                    # for the backfill loop to work properly
                    sortedMappedPoints = do ->
                        return _.sortBy(mappedPoints, "calendar__timestamp")
                    backfillOffset = 0
                    yAxis: 0
                    turboThreshold: 1000000
                    dataLabels:
                        formatter: -> valueFormatter(model.selected.metric.type, @y)
                    name: rows[0].property0
                    data: sortedMappedPoints.flatMap (row, index) -> Array.from do ->
                        point = createSeriesPoint(row)
                        total = totalSeries[index+backfillOffset]
                        while total.name isnt point.name or backfillOffset > 10000
                            yield {..._.omit(total, 'fullInfo'), y: 0}
                            backfillOffset++
                            total = totalSeries[index+backfillOffset]
                        throw new Error('bug; too many iterations on chart backfill') if backfillOffset > 10000
                        yield point
                        return
                    type: do ->
                        return 'areaspline' if model.selected.stacking
                        return 'line'

                # create a data series for each grouping
                groupedSeries  = _.groupBy(dimensionSeries, 'property0')
                mappedSeries   = _.mapValues(groupedSeries, (grouping) -> createSeries(grouping))
                combinedSeries = Object.values(mappedSeries)
                combinedSeries = _.sortBy(combinedSeries, 'name')

            chart:
                zoomType: 'x'
                events:
                    drilldown: (e) ->
                        chartType = model.selected.timeGrouping.chartType
                        selectedDrilldown = do =>
                            if chartType is 'barchart'
                                return e.point.name
                            if chartType is 'timeseries'
                                seriesIndex = do =>
                                    seriesPointIndex = e.point?.series?.index
                                    return seriesPointIndex if _.isNumber(seriesPointIndex)
                                    # workaround for bug in highcharts: http://stackoverflow.com/questions/38534164/highcharts-drilldown-on-area-chart
                                    return _.findIndex @series, (series) -> $(e.originalEvent.composedPath[1]).children().is(series.area?.element)
                                return @series[seriesIndex]?.name

                        # apply the current selected level as a filter
                        {table, column} = model.selected.grouping
                        model.selected.filters[table] ?= {}
                        model.selected.filters[table][column] = selectedDrilldown

                        # remove funnel.selected and everything after it
                        if not _.isEmpty(model.funnel.selected)
                            model.funnel.nodes = model.funnel.nodes[0 ... model.findIndexById(model.funnel.nodes, model.funnel.selected.id)]
                            model.funnel.selected = {}

                        # add drilldown to the funnel
                        model.funnel.nodes.push Utils.copy(model.selected.grouping)
                        _.last(model.funnel.nodes).choice = selectedDrilldown

                        model.groupByNext()

                        # destroy the chart to avoid triggering drilldown twice
                        @destroy()

            accessibility:
                enabled: false
            exporting:
                fallbackToExportServer: false
                scale: 2
                sourceHeight: 600,
                sourceWidth: 1000
            title:
                text: null
            xAxis:
                type: 'category'
                categories: xAxisCategories
                labels:
                    rotation: -45
                    align: 'right'
            drilldown:
                activeAxisLabelStyle:
                    textDecoration: 'none'
                    color: '#424448'
                    fontSize: '9px'
                    fontWeight: 'normal'
                    textTransform: 'none'
                    fill: '#424448'

            yAxis: yAxes
            series: combinedSeries
            legend:
                enabled: true
                verticalAlign: "top"
                symbolRadius: "50px"
                squareSymbol: true
                symbolWidth:  10
                symbolHeight: 10
                itemDistance: 15
                padding: 0
                margin: 35
                marginBottom: 15
                y: 0
                itemHiddenStyle:
                    color: "#aaa"
                itemStyle:
                    fontSize: "11px"
            tooltip: do (model) ->
                formatters = TooltipFormatters(valueFormatter)
                return formatters[model.selected.timeGrouping.chartType](model)
            plotOptions:
                series:
                    connectNulls: true
                    borderRadiusTopLeft: '4px'
                    borderRadiusTopRight: '4px'
                    cursor: 'pointer'
                    borderWidth: 0
                    marker:
                        radius: 2
                    lineWidth: 1
                areaspline:
                    connectNulls: true
                    trackByArea: true
                    stacking: if model.selected.stacking then "normal" else undefined
]


TooltipFormatters = (valueFormatter) ->

    # NOTE: calling purify because this will also be used by highcharts tooltip
    # that will interpret the node as HTML
    tooltipPointElement = ({metric, color, y, series}) ->
        tr = document.createElement('tr')
        label = document.createElement('td')
        label.style.color = purifyText(color)
        label.appendChild(document.createTextNode(purifyText(series.name.trim())))
        label.appendChild(document.createTextNode(":\u00A0"))
        value = document.createElement('td')
        value.innerText = (try purifyText(valueFormatter(metric.type, y))) ? ""
        tr.appendChild(label)
        tr.appendChild(value)
        return tr

     ###* @param {{
        selected: {
            timeGrouping : import('./store-chart-time-groupings').ITimeGrouping,
            metric       : {label: string}
        },
    }} model ###
    timeseries: (model) ->
        shared: true
        useHTML: true
        formatter: ->
            fullInfo = do =>
                ref = @points.find((x) -> Boolean(x.point.fullInfo))
                return ref?.point.fullInfo
            headerLabel = do ->
                return model.selected.timeGrouping.tooltipFormatter(fullInfo)
            pointElements = do =>
                return [] if not fullInfo
                return @points.map (p) -> tooltipPointElement
                    metric: model.selected.metric,
                    series: p.point.series,
                    color: p.point.color,
                    y: p.y
            title = document.createElement('span')
            title.innerText = _.compact([model.selected.metric.label, headerLabel]).join(' for ')
            valuesContainer = document.createElement('table')
            pointElements.forEach (e) -> valuesContainer.appendChild(e)
            return "#{title.outerHTML}<br>#{valuesContainer.outerHTML}"

    barchart: (model) ->
        shared: true
        useHTML: true
        headerFormat: do ->
            group = purifyText(model.selected.metric.group)
            return "<span>#{group} for <em>{point.key}</em></span><br><table>"
        pointFormatter: (point) ->
            metric = _.find model.available.metric, {label: @series.name}
            html = tooltipPointElement({y:@y, metric, color:@color, series:@series})
            return html.outerHTML
        footerFormat: "</table>"
