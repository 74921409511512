import { z } from 'zod';
import _ from 'lodash';
import { IConfigObj, IQuery, IQueryColumnFilterValue, IQueryFilters, IQueryTableFilter } from '../../lib/types';
import Utils, { isObject } from '../../lib/utils';
import { StorageAPI, StorageAPISlice } from '../../lib/storage-user-config';
import { ISelectModel, SelectModel } from '../../lib/model/model-select';
import { ConfigAPI } from '../../lib/config-api';
import { createDuplicateLabel } from '../../lib/model/model-utils';
import { compactQueryFilters } from '../../lib/query/query-reader';
import { Parse } from '../../lib/parsers/values';
import { logError } from '../../lib/analytics';
import { deepStripAngularProperties } from '../../lib/angular';
import * as Analytics from '../../lib/analytics';
import { downloadFile } from '../../lib/services/download-file';
import { IHierarchy, IPropertyDefinition } from '../../lib/config-hierarchy';
import { HierarchyService } from '../hierarchy';

const SMART_GROUP_DEFAULT_NAME = 'Untitled Segment';

// This is for debugging; will give you blank groups and it won't save them...
const DISABLE_PERSISTENT_STATE = false;
if (DISABLE_PERSISTENT_STATE) {
    logError(new Error('PERSISTENT SEGMENT (SMART GROUP) STATE IS DISABLED!'));
}

export interface ISmartGroupFilterDescriptor {
    id: string;
    label: string;
    instructions: string;
    hierarchy?: IHierarchy;
    /** @deprecated just putting this to see usage so that we can refactor it out */
    tables: string[];
}

// FIXME: probably need to fetch the property definitions from config
// and set the "tables" here
export const SmartGroupFilterDescriptors = {
    fetch() {
        const getConfig = () => ConfigAPI.get().then(api => api.organization.get());
        return Promise.all([getConfig(), HierarchyService().fetch()]).then(([config, hierarchy]) => {
            const propertiesById = _.keyBy(hierarchy.groupBy, 'id');
            return this.get(config, propertiesById);
        });
    },
    get(config: IConfigObj, propertiesById?: Record<string, IPropertyDefinition>) {
        const [storesHierarchy, itemsHierarchy] = (() => {
            if (!propertiesById) return [config.stores.hierarchy, config.items.hierarchy];
            return [config.stores.hierarchy, config.items.hierarchy].map((properties: IPropertyDefinition[]) => {
                return properties.filter(p => propertiesById[p.id]);
            });
        })();

        return [
            {
                id: 'stores',
                tables: ['stores', 'acquisitions', 'employees', 'accounts', 'warehouses', 'customers'],
                label: config.stores?.label || 'Store Filter',
                instructions: `
                This filter is used to find customers that shopped at one or more stores.
                `,
                hierarchy: storesHierarchy,
            },
            {
                id: 'items',
                tables: ['items', 'transaction_items', 'item_timeseries', 'gift_cards', 'demand_items'],
                label: config.items?.label || 'Item Filter',
                instructions: `
                This filter allows you to find customers that have
                purchased a particular type of item, or an item of
                a certain category.
                `,
                hierarchy: itemsHierarchy,
            },
        ];
    },
};

export interface IFilterPopupState<T = unknown> {
    descriptor: ISmartGroupFilterDescriptor;
    filters: IQueryFilters;
    context?: undefined | T;
}
export interface IFilterPopupModel<T = unknown> {
    state: null | IFilterPopupState<T>;
    onSave: null | ((filters: IQueryFilters) => void);
    open(state: IFilterPopupState<T>, onSave: (filters: IQueryFilters) => void): void;
    close(): void;
}

export class SmartGroupsPopupModel<T extends { group: ISmartGroupState } = { group: ISmartGroupState }>
    implements IFilterPopupModel<T>
{
    state: null | IFilterPopupState<T> = null;
    onSave: null | ((filters: IQueryFilters) => void) = null;
    open(state: IFilterPopupState<T>, onSave: (filters: IQueryFilters) => void) {
        const { descriptor, filters, context } = state;
        this.onSave = onSave;
        this.state = _.cloneDeep({ descriptor, filters: { ...(filters ?? {}) }, context });
    }
    close() {
        this.state = null;
    }
}

const getPropertyFilters = (queryFilters: IQueryFilters) => {
    return Object.keys(queryFilters).reduce<string[]>((acc, property) => {
        const propertyObj = queryFilters[property] ?? {};
        if ('$and' in propertyObj && Array.isArray(propertyObj.$and) && propertyObj.$and.length > 0) {
            propertyObj.$and.forEach(propertyFilterItem => {
                Object.keys(propertyFilterItem).forEach(propertyFilter => {
                    acc.push(`${property}.${propertyFilter}`);
                });
            });
        }

        return acc;
    }, []);
};

export class SmartGroupFilterViewModel {
    readonly id: string;
    readonly isEmpty: boolean = false;
    readonly isEditing: boolean = false;
    constructor(readonly group: SmartGroupViewModel, readonly descriptor: ISmartGroupFilterDescriptor) {
        this.id = `${group.id}/${descriptor.id}`;
        this.descriptor = _.cloneDeep(this.descriptor);
        this.group = group;
        this.isEmpty = (() => {
            const result = _.isEmpty(_.pick(this.group.query.filters, this.descriptor.tables));
            if (result && Array.isArray(descriptor.hierarchy)) {
                const { hierarchy } = descriptor;
                const propertyFilters = hierarchy.map(h => h.id);
                const queryFilters = _.omit(this.group.query.filters, this.descriptor.tables);
                const selectedPropertyFilters = getPropertyFilters(queryFilters);

                return !selectedPropertyFilters.some(selectedPropertyFilter =>
                    propertyFilters.includes(selectedPropertyFilter),
                );
            }

            return result;
        })();
    }
    select() {
        this.group.selectFilter(this);
    }
}

export class SmartGroupViewModel implements ISmartGroupState {
    readonly id: string;
    readonly name: string;
    readonly query: { filters: IQueryFilters };
    readonly filters: SmartGroupFilterViewModel[];
    constructor(
        readonly parent: ISmartGroupsViewModel,
        group: ISmartGroupState,
        filters: ISmartGroupFilterDescriptor[],
    ) {
        this.id = group.id;
        this.name = group.name;
        this.query = _.cloneDeep({ filters: { ...group.query?.filters } });
        this.filters = filters.map(x => new SmartGroupFilterViewModel(this, x));
    }
    selectFilter(filter: SmartGroupFilterViewModel) {
        this.parent.selectGroupFilter(filter);
    }
    select() {
        return this.parent.selectGroup(this);
    }
    update(update: Partial<ISmartGroupState>) {
        if (_.isEmpty(update)) return;
        console.log('Updating Segment from SmartGroupViewModel:', update);
        const name = update.name ?? this.name;
        return this.parent.update({ ...update, name, id: this.id });
    }
    delete() {
        return this.parent.delete(this);
    }
    duplicate() {
        return this.parent.duplicate(this);
    }
    export() {
        return this.parent.export(this);
    }
}

type ISmartGroupViewModelGroups = ISelectModel<SmartGroupViewModel>;

export interface ISmartGroupsViewModel extends ISmartGroupsModel {
    readonly popup: SmartGroupsPopupModel;
    readonly groups: ISmartGroupViewModelGroups;
    selectGroupFilter(filter: SmartGroupFilterViewModel): void;
    selectGroup(group: ISmartGroupState | string): void;
    getSelected(): ISmartGroupState;
    getSelectedId(): string;
    ValidateViewConfigFile(payload: unknown, orgId: string): ISmartGroupState | ISmartGroupState[];
    model: ISmartGroupsModel;
}

const SegmentMetaSchema = z.object({
    createdAt: z.number(),
    organizationId: z.string(),
    exportedByUserId: z.string(),
    type: z.literal('segment'),
    version: z.number(),
});

const SegmentDataSchema = z.object({
    id: z.string(),
    name: z.string(),
    query: z.record(z.unknown()),
    color: z.string().optional().nullish(),
});

const SegmentListDataSchema = z.array(SegmentDataSchema);

const SmartGroupsViewModelFactory = (SmartGroupFilters: ISmartGroupFilterDescriptor[]) =>
    class SmartGroupsViewModel implements ISmartGroupsViewModel {
        readonly popup: SmartGroupsPopupModel;
        readonly groups: ISmartGroupViewModelGroups;
        readonly hierarchyPropertiesByGroup: Record<string, Record<string, string[]>>;
        model: ISmartGroupsModel;

        constructor(model: ISmartGroupsModel, groups: ISmartGroupState[]) {
            this.model = model;
            this.popup = new SmartGroupsPopupModel();
            this.groups = new SelectModel(groups.map(group => new SmartGroupViewModel(this, group, SmartGroupFilters)));
            this.hierarchyPropertiesByGroup = SmartGroupFilters.reduce<Record<string, Record<string, string[]>>>(
                (acc, descriptor) => {
                    const { id, hierarchy, tables } = descriptor;
                    acc[id] = {};
                    if (Array.isArray(hierarchy)) {
                        const nonDefaultTableProperties = hierarchy.reduce<Record<string, string[]>>(
                            (nDefaultTableProperties, h) => {
                                const table = h.id.split('.')[0] ?? null;
                                if (!table) return nDefaultTableProperties;
                                if (tables.includes(table)) return nDefaultTableProperties;

                                nDefaultTableProperties[table] = nDefaultTableProperties[table] ?? [];
                                nDefaultTableProperties[table].push(h.id);

                                return nDefaultTableProperties;
                            },
                            {},
                        );

                        if (!_.isEmpty(nonDefaultTableProperties)) {
                            acc[id] = nonDefaultTableProperties;
                        }
                    }

                    return acc;
                },
                {},
            );
        }
        getSelectedQuery() {
            return this.groups.view.selected?.model.query ?? null;
        }
        // for debugging
        getSelectedQueryStr() {
            return JSON.stringify(this.getSelectedQuery(), null, 2);
        }
        selectGroupFilter(model: SmartGroupFilterViewModel) {
            const { descriptor, group } = model;
            let filters = _.cloneDeep({ ...group.query.filters });
            this.popup.open({ descriptor, filters }, update => {
                const { hierarchy } = model.descriptor;
                let queryFilters: IQueryFilters = {};
                if (Array.isArray(hierarchy)) {
                    queryFilters = _.assignWith<IQueryFilters>(
                        group.query.filters,
                        update,
                        (oldFilter: IQueryTableFilter, updatedFilters: IQueryTableFilter, key: string) => {
                            if (_.isEmpty(oldFilter)) return updatedFilters;
                            if (model.descriptor.tables.includes(key)) return updatedFilters;

                            const hierarchyProperty = this.hierarchyPropertiesByGroup[model.descriptor.id] ?? {};
                            const properties = hierarchyProperty[key] ?? [];
                            const mergedProperty = Object.keys(oldFilter).reduce<IQueryTableFilter>((acc, operator) => {
                                let oldFilterValues: Record<string, IQueryColumnFilterValue> = {};
                                const oldFilterOperatorValues = oldFilter[operator];
                                if (Array.isArray(oldFilterOperatorValues)) {
                                    oldFilterValues = oldFilterOperatorValues.reduce<
                                        Record<string, IQueryColumnFilterValue>
                                    >((acc, item) => {
                                        for (const [itemKey, value] of Object.entries(item)) {
                                            acc = {
                                                ...acc,
                                                ...{ [itemKey]: value },
                                            };
                                        }
                                        return acc;
                                    }, {});
                                }
                                acc[operator] = updatedFilters[operator] ?? [];
                                const operatorValues = acc[operator];
                                if (!Array.isArray(operatorValues)) return acc;
                                for (const [propertyFilter, value] of Object.entries(oldFilterValues)) {
                                    const propertyFilterId = `${key}.${propertyFilter}`;

                                    if (!properties.includes(propertyFilterId))
                                        operatorValues.push({ [propertyFilter]: value });
                                }
                                return acc;
                            }, {});

                            return mergedProperty;
                        },
                    );
                } else {
                    queryFilters = { ...group.query.filters, ...update };
                }

                filters = compactQueryFilters(queryFilters);
                this.model.update({ ...group, query: { filters } });
                this.popup.close();
            });
        }

        selectGroup(group: { id: string } | string) {
            const id = typeof group === 'string' ? group : group.id;
            if (id) this.groups.select(id);
            this.popup.close();
        }
        reorder(prev: number, next: number) {
            return this.model.reorder(prev, next);
        }
        update(group: ISmartGroupState) {
            return this.model.update(group);
        }
        create(group?: Partial<ISmartGroupState>) {
            return this.model.create(group);
        }
        delete(group: ISmartGroupState) {
            return this.model.delete(group);
        }
        duplicate(group: ISmartGroupState) {
            return this.model.duplicate(group);
        }
        export(group: ISmartGroupState) {
            return this.model.export(group);
        }
        exportAll() {
            return this.model.exportAll();
        }
        getSelected() {
            return this.groups.getSelected();
        }
        getSelectedId() {
            return this.groups.getSelectedId();
        }
        import(newState: ISmartGroupState | ISmartGroupState[]) {
            this.model.import(newState);
        }

        ValidateViewConfigFile(payload: unknown, orgId: string): ISmartGroupState | ISmartGroupState[] {
            try {
                if (typeof payload === 'string') payload = JSON.parse(payload);
            } catch (error) {
                console.error(error);
                throw new Error('View config import error: bad json.', { cause: error });
            }

            if (!isObject(payload)) {
                throw new Error('View config import error: must be a string or object');
            }

            const meta = SegmentMetaSchema.safeParse(payload.meta);
            if (!meta.success) {
                console.error(meta.error);
                throw new Error('View config import error: meta field missing or invalid.');
            }

            if (meta.data.organizationId !== orgId) {
                throw new Error('View config import error: incorrect organization.');
            }

            if (isObject(payload.data)) {
                const parsedData = SegmentDataSchema.safeParse(payload.data);
                if (!parsedData.success) {
                    console.error(parsedData.error);
                    throw new Error('View config import error: data field missing or invalid.');
                }
                return parsedData.data;
            }

            if (Array.isArray(payload.data)) {
                const parsedData = SegmentListDataSchema.safeParse(payload.data);
                if (!parsedData.success) {
                    console.error(parsedData.error);
                    throw new Error('View config import error: data field missing or invalid.');
                }
                return parsedData.data;
            }

            throw new Error('View config import error: invalid format');
        }
    };
export interface ISmartGroupsModel {
    create(group?: Partial<ISmartGroupState>): ISmartGroupState;
    duplicate(group: Partial<ISmartGroupState>): ISmartGroupState;
    update(group: Partial<ISmartGroupState>): ISmartGroupState;
    reorder(oldIndex: number, newIndex: number): void;
    delete(group: ISmartGroupState): void;
    import(group: ISmartGroupState | ISmartGroupState[]): void;
    export(group: ISmartGroupState): void;
    exportAll(): void;
    view?: ISmartGroupsViewModel;
}

export const SmartGroupsModelService = {
    fetch() {
        return Promise.all([SmartGroupsStorage.fetch(), SmartGroupFilterDescriptors.fetch()]).then(
            ([SmartGroupStorage, SmartGroupFilters]) => {
                return SmartGroupsModelFactory(SmartGroupStorage, SmartGroupFilters);
            },
        );
    },
};

export interface ISmartGroupState {
    id: string;
    name: string;
    query: IQuery;
    color?: undefined | string;
}

export const SmartGroupsModelFactory = (
    SmartGroupStorage: SmartGroupsStorage,
    SmartGroupFilters: ISmartGroupFilterDescriptor[],
) => {
    const SmartGroupsViewModel = SmartGroupsViewModelFactory(SmartGroupFilters);

    return class SmartGroupsModel implements ISmartGroupsModel {
        protected storage: ISmartGroupStorage;
        protected state: ISmartGroupState[];
        public view: ISmartGroupsViewModel;

        constructor(protected hierarchy: undefined | null | string) {
            this.storage = new SmartGroupsStorageChild(SmartGroupStorage, hierarchy);
            const state = this.getState();
            if (state.length === 0) state.push(SmartGroupsModel.CreateGroup());
            this.state = this.storage.setState({ state });
            this.view = new SmartGroupsViewModel(this, this.state);
        }

        getState(): ISmartGroupState[] {
            return this.storage.getState();
        }

        create({ name, query }: undefined | Partial<ISmartGroupState> = {}) {
            const state = this.getState();
            const group = SmartGroupsModel.CreateGroup({
                ...(name ? { name } : {}),
                ...(query ? { query } : {}),
            });
            console.info(`[Segments]: Created segment w/ id ${group.id}:`, this.state);
            this.setState([group, ...state], group.id);
            return _.cloneDeep(group);
        }

        duplicate(group: ISmartGroupState) {
            const [existing, index, state] = this.find(group.id);
            if (!existing) throw new Error('Cannot duplicate; segment does not exist');
            const copy = SmartGroupsModel.CreateGroup(group);
            copy.name = createDuplicateLabel(copy.name);
            const update = Utils.Array.insertAt(state, index, copy);
            this.setState(update, copy.id);
            return _.cloneDeep(copy);
        }

        update({ id, ...patch }: Omit<Partial<ISmartGroupState>, 'id'> & { id: string }) {
            if (!id) throw new Error('Missing required: group.id');
            let [group, index, state] = this.find(id);
            if (!group) throw new Error(`Segment '${id}' does not exist.`);
            const color = Parse.String(patch.color) ?? group.color;
            const name = Parse.String(patch.name) ?? group.name;
            const query = patch.query ? _.cloneDeep(patch.query) : group.query;
            group = { id: group.id, color, name, query };
            state[index] = group;
            this.setState(state, group.id);
            console.info(`[Segments]: Updated segment w/ id '${group.id}':`, this.state);
            return _.cloneDeep(group);
        }

        reorder(prev: number, next: number) {
            const state = this.getState();
            const update = Utils.Array.move(state, prev, next);
            this.setState(update);
            console.info(`[Segments]: Reordered segment that was at index '${prev}' to '${next}':`, this.state);
        }

        delete({ id }: ISmartGroupState) {
            const [existing, index, state] = this.find(id);
            if (!existing) throw new Error('Cannot remove segment; id not found.');
            const nextState = Utils.Array.remove(state, x => x.id === existing.id);
            const nextIndex = Math.max(0, index - 1);
            let next = nextState[nextIndex];
            if (!next) nextState.push((next = SmartGroupsModel.CreateGroup()));
            this.setState(nextState, next.id);
            console.info(`[Segments]: Deleted segment w/ id ${id}:`, this.state);
        }

        export(group: ISmartGroupState) {
            const [existing] = this.find(group.id);
            if (!existing) return;
            void downloadFile({
                data: deepStripAngularProperties(existing),
                name: group.name,
                type: 'segment',
                namespace: 'segment',
                analyticsEvent: Analytics.EVENTS.USER_EXPORT_SEGMENT,
            });
        }

        exportAll() {
            const state = this.getState();

            void downloadFile({
                data: state.map(deepStripAngularProperties),
                name: 'all-segments',
                type: 'segment',
                analyticsEvent: Analytics.EVENTS.USER_EXPORT_ALL_SEGMENTS,
            });
        }

        import(newState: ISmartGroupState | ISmartGroupState[]) {
            const state = this.getState();
            if (!Array.isArray(newState)) {
                const { name, query } = newState;
                const group = SmartGroupsModel.CreateGroup({ name, query });
                console.info(`[Segments]: Created segment w/ id ${group.id}:`, this.state);
                this.setState([group, ...state], group.id);
            } else {
                const groups = newState.map(newGroup => {
                    const { name, query } = newGroup;
                    return SmartGroupsModel.CreateGroup({ name, query });
                });

                const ids = groups.map(group => group.id);
                console.info(`[Segments]: Created segments w/ id ${ids}:`, this.state);
                this.setState([...state, ...groups], ids[0]);
            }
        }

        protected setState(state: ISmartGroupState[], selected?: null | string | ISmartGroupState) {
            this.state = this.storage.setState({ state });

            this.view.popup.close();
            selected ??= this.view.getSelectedId();
            this.view = new SmartGroupsViewModel(this, this.state);
            this.view.selectGroup(selected);

            return { state: this.state, view: this.view };
        }

        protected find(id: string): [ISmartGroupState | null, number, ISmartGroupState[]] {
            const state = this.getState();
            const index = state.findIndex(g => g.id === id);
            const group = state[index];
            return group ? [_.cloneDeep(group), index, state] : [null, index, state];
        }

        protected static CreateGroup(from?: Partial<ISmartGroupState>): ISmartGroupState {
            return createSmartGroup(from);
        }
    };
};

interface ISmartGroupStorage {
    getState(): ISmartGroupState[];
    setState(params: { state: ISmartGroupState[] }): ISmartGroupState[];
}

class SmartGroupsStorageChild implements ISmartGroupStorage {
    constructor(protected storage: SmartGroupsStorage, protected hierarchy: null | undefined | string) {}
    getState() {
        const result = this.storage.getState(this.hierarchy);
        this.hierarchy = result.hierarchy;
        return result.state;
    }
    setState(params: { state: ISmartGroupState[] }) {
        const result = this.storage.setState({ hierarchy: this.hierarchy, state: params.state });
        return result.state;
    }
}

class SmartGroupsStorage {
    static fetch() {
        const storage = StorageAPI('groups');
        return Promise.all([storage, storage.then(api => api.get())]).then(
            ([storage, state]) => new SmartGroupsStorage(storage, state),
        );
    }

    protected state: unknown[] | Record<string, unknown[]>;

    constructor(protected api: StorageAPISlice, state: unknown) {
        this.state = normalizeSmartGroupsStoredState(state);
        if (DISABLE_PERSISTENT_STATE) this.state = [];
    }

    setState(params: { hierarchy?: null | undefined | string; state: ISmartGroupState[] }) {
        this.state = (() => {
            const groups = Array.from(normalizeSmartGroups(params.state));
            if (!params.hierarchy) return groups;
            return { ...this.state, [params.hierarchy]: groups };
        })();
        const promise = this.saveToStorage(this.state) ?? Promise.resolve();
        return { promise, ...this.getState(params.hierarchy) };
    }

    getState(hierarchy?: null | undefined | string) {
        if (Array.isArray(this.state)) {
            const slice = [...this.state];
            this.state = hierarchy ? { [hierarchy]: slice } : this.state;
            return { hierarchy: null, state: Array.from(normalizeSmartGroups(slice)) };
        } else {
            hierarchy ??= Object.keys(this.state ?? {})[0];
            hierarchy ??= null;
            this.state = { ...this.state };
            const slice = [...((hierarchy ? this.state[hierarchy] : null) ?? [])];
            return { hierarchy, state: Array.from(normalizeSmartGroups(slice)) };
        }
    }

    protected saveToStorage = _.debounce((state: unknown) => {
        if (DISABLE_PERSISTENT_STATE) return;
        return this.api.put(_.cloneDeep(state)).then(() => {});
    }, 200);
}

// FIXME: bad type assertion
const isSmartGroupQueryFilters = (x: unknown): x is IQueryFilters => {
    return Utils.Object.isObject(x);
};

function createSmartGroup(from?: Partial<ISmartGroupState>): ISmartGroupState {
    const id = Utils.Misc.uuid();
    const name = Parse.String(from?.name) ?? SMART_GROUP_DEFAULT_NAME;
    const color = Parse.String(from?.color) ?? undefined;
    const query: IQuery = (() => {
        const query = Utils.Object.isObject(from?.query) ? from?.query : undefined;
        const filters = isSmartGroupQueryFilters(query?.filters) ? query?.filters : undefined;
        return { filters: filters ?? {} };
    })();
    return { id, name, color, query: _.cloneDeep(query) };
}

function* normalizeSmartGroups(groups: unknown[]): Iterable<ISmartGroupState> {
    groups = Array.isArray(groups) ? groups : [];

    const seen = new Set<string>();
    for (const group of groups) {
        if (!Utils.Object.isObject(group)) continue;

        let id = Parse.String(group.id);
        const name = Parse.String(group.name);
        if (!id && !name) continue;

        // If we find a duplicate ID, then we use the copy's id which should be unique.
        const copy = createSmartGroup(group);
        id = id && !seen.has(id) ? id : copy.id;
        seen.add(id);

        yield { ...copy, id };
    }
}

function normalizeSmartGroupsStoredState(state: unknown): Record<string, unknown[]> | unknown[] {
    if (Array.isArray(state)) return state;
    // If the state is an object, then we get the first key.
    // Just a precaution to not break anything from multi-hierarchy removal.
    if (isObject(state)) {
        const key = Object.keys(state)[0];
        const stateResult = typeof key === 'string' ? state[key] : [];
        return Array.isArray(stateResult) ? stateResult : [];
    }
    return [];
}
