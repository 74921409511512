import { Component } from 'react';
import { react2angular } from "react2angular";

interface CloseButtonDirectiveScope extends angular.IScope {
    onClose: () => void;
}

class ButtonClose extends Component<CloseButtonDirectiveScope> {
    override render() {
        return (
            <div className="button-close" onClick={this.props.onClose}>
                <i className="icon-cancel"></i>
                <i className="icon-cancel-circled button-close"></i>
            </div>
        );
    }
}

const buttonCloseReactModule = angular
    .module('42.components.button-close-react', [])
    .component('buttonCloseReact', react2angular(ButtonClose, ["onClose"]));

export default buttonCloseReactModule;
