import _ from 'lodash';
import { z } from 'zod';
import Utils from '../../lib/utils';
import * as Analytics from '../../lib/analytics';
import { AngularInjected, deepStripAngularProperties } from '../../lib/angular';
import { IConfigObj, IMetricDefinition, IQuery } from '../../lib/types';
import { StorageAPI } from '../../lib/storage-user-config';
import { getOrganization } from '../../lib/auth';
import * as ConfigFlags from '../../lib/config-flags';
import * as ConfigExperimentsAPI from '../../lib/config-experiments';
import { IPropertyDefinition, normalizePropertyDefinition } from '../../lib/config-hierarchy';
import { HierarchyService } from '../../modules/hierarchy/hierarchy.module';
import { ToggleModel } from '../../lib/model/model-toggle';
import { downloadFile } from '../../lib/services/download-file';
import { IDataDescriptorsService } from '../../modules/services';
import { SmartGroupViewModel } from '../../modules/smart-groups/smart-groups.service';
import { DashboardRootScope, IQueryMetrics } from '../main-controller';
import {
    ActionsPanelModel,
    ActionsPanelButtonItem,
    ActionsPanelItem,
    ActionsPanelSecondaryItem,
    ActionsPanelSelectItem,
} from '../../components/actions-panel';
import { SidebarModel, SidebarToggleModel } from '../../components/sidebar';
import { PropertiesItemsModel } from '../../components/properties-items';
import { TimeGroupings } from './store-chart-time-groupings';
import { IHighchartsModel, IHighchartsModelSortOrder, IHighchartsSelectedModel } from './store-controller';
import { DragAndDropExternalAPI } from '../../components/drag-and-drop';

export const ChartPageRouteConfigFactory = () =>
    function ChartPageRouteConfig(
        $routeProvider: angular.route.IRouteProvider,
        ROUTES: Record<string, unknown>,
        CONFIG: IConfigObj,
    ) {
        const override = _.pick(CONFIG.routes?.chart ?? CONFIG.routes?.stores ?? {}, 'label', 'url');
        const route: Record<string, unknown> = ROUTES.chart ? { ...ROUTES.chart, ...override } : { ...override };

        if (typeof route.url === 'string') {
            if (route.oldUrl && typeof route.oldUrl === 'string') {
                $routeProvider.when(route.oldUrl, { redirectTo: route.url });
            }

            $routeProvider.when(route.url, route);
        }
    };

// FIXME: centralize with definition from main-controller
const fetchHourProperty = (): Promise<IPropertyDefinition | null> => {
    return ConfigFlags.fetch().then(flags => {
        if (!flags.showHourDimension) return null;
        const property = 'transactions.timestamp__hour';
        return normalizePropertyDefinition({
            id: property,
            label: 'Hour',
            sort: { field: property, order: 1 },
        });
    });
};

export interface IChartPageViewParams {
    groupBy: IPropertyDefinition[];
    metrics: IMetricDefinition[];
}

export interface IChartPageViewFactoryConfig {
    segmentName: string | undefined;
}
export interface IChartPageViewConfig {
    segmentName?: string | undefined;
    metrics: IMetricDefinition[];
    groupBy: IPropertyDefinition[];
    name?: string | undefined;
    id?: string;
    model?: IHighchartsModel | null | undefined;
}

export type IChartPageViewFactory = typeof ChartPageView;
export type IChartPageView = InstanceType<IChartPageViewFactory>;
export class ChartPageView {
    id: string;
    name: string;
    model: IHighchartsModel | null = null;
    segmentName = '';
    params: IChartPageViewParams | undefined = undefined;

    constructor(options: IChartPageViewConfig) {
        this.id = options.id ?? Utils.uuid();
        this.model = options.model ?? null;
        this.name = options.name ?? 'New View';
        this.segmentName = options.segmentName ?? '';
        this.params = {
            groupBy: options.groupBy,
            metrics: options.metrics,
        };
    }

    setSegmentName(name: string) {
        this.segmentName = name || '';
    }
}

async function fetchGroupByHierarchy() {
    return Promise.all([HierarchyService().fetch(), fetchHourProperty()]).then(([hierarchy, hourProperty]) => {
        if (hourProperty) hierarchy.groupBy.push(hourProperty);
        return hierarchy.groupBy;
    });
}

interface StoreActionsPanelDirectiveScope extends angular.IScope {
    tab: ChartPageView;
    showTimeGrouping: boolean;
    actionsPanelModel: ActionsPanelModel;
    options: {
        enableTimeGrouping: boolean;
        groupByPropertiesInSidebar: boolean;
        sidebar: boolean;
    };
    pebblesGroupByModel: PropertiesItemsModel;
    save: () => void;
    toggle: {
        panel: ToggleModel;
        sidebar: SidebarToggleModel;
    };
    isSidebarDisabled: boolean;
    hidePanel: ($event: Event) => void;
    togglePanel: () => void;
}

export const StoreActionsPanelDirective = () => [
    function StoreActionsPanelDirectiveFn(): angular.IDirective<StoreActionsPanelDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                tab: '=',
                toggle: '=',
                options: '=',
            },
            replace: true,
            template: `
                <article class="store-actions-panel">
                    <div class="actions-panel-row selected-filters-row">
                        <div class="selected-filters">
                            <store-funnel-state model="tab.model" label="tab.segmentName"> </store-funnel-state>
                        </div>
                    </div>
                    <div class="actions-panel-row property-items-row">
                        <properties-items ng-if="pebblesGroupByModel" model="pebblesGroupByModel"></properties-items>
                    </div>
                    <div class="actions-panel-row last-row"
                        ng-if="isSidebarDisabled && actionsPanelModel.items.length > 0">
                        <actions-panel model="actionsPanelModel"></actions-panel>
                    </div>
                </article>
            `,
            link: function StoreActionsPanelDirectiveLink($scope) {
                const buildLimit = (tab: ChartPageView) => {
                    const limitBy = 20;
                    const limits = (tab.model?.available.limitBy ?? [limitBy]).map(limitBy => ({
                        id: limitBy.toString(),
                        label: limitBy,
                    }));
                    const isBarChart = Boolean($scope.tab.model?.selected.timeGrouping.chartType === 'barchart');
                    const available = limits.filter(x => isBarChart || x.label <= 50);
                    const selected = available.find(limit => limit.label === tab.model?.selected.limitBy) ?? {
                        label: limitBy,
                    };

                    return { available, selected };
                };

                $scope.$watch(
                    'tab.model.selected',
                    (selectedModel: IHighchartsSelectedModel | undefined) => {
                        if (!selectedModel || !$scope.tab.model?.available) return;

                        $scope.isSidebarDisabled =
                            !$scope.options.sidebar || !$scope.options.groupByPropertiesInSidebar;

                        if ($scope.isSidebarDisabled) {
                            $scope.pebblesGroupByModel = {
                                label: 'Group By',
                                available: $scope.tab.model.selected.properties,
                                selected: $scope.tab.model.selected.grouping,
                                onClick: properties => {
                                    if ($scope.tab.model && properties[0])
                                        $scope.tab.model.selected.grouping = properties[0];
                                },
                            };
                        }

                        const limits = buildLimit($scope.tab);
                        $scope.actionsPanelModel = new ActionsPanelModel({
                            items: [
                                ...($scope.options.enableTimeGrouping
                                    ? [
                                          new ActionsPanelSelectItem({
                                              label: 'Time Grouping',
                                              available: $scope.tab.model.available.timeGrouping,
                                              selected: $scope.tab.model.selected.timeGrouping,
                                              onClick: item => {
                                                  if ($scope.tab.model) $scope.tab.model.updateTimeGrouping(item);
                                              },
                                          }),
                                      ]
                                    : []),
                                new ActionsPanelSelectItem({
                                    label: (() => {
                                        if ($scope.tab.model.selected.timeGrouping.chartType === 'barchart') {
                                            return 'Sort By';
                                        }
                                        if ($scope.tab.model.selected.timeGrouping.chartType === 'timeseries') {
                                            return 'Metric';
                                        }
                                        return '';
                                    })(),
                                    available: $scope.tab.model.available.metric,
                                    selected: $scope.tab.model.selected.metric,
                                    onClick: metric => {
                                        if ($scope.tab.model?.selected) $scope.tab.model.selected.metric = metric;
                                    },
                                }),
                                new ActionsPanelSelectItem({
                                    label: 'Sort Order',
                                    available: $scope.tab.model.available.sortOrder,
                                    selected: $scope.tab.model.selected.sortOrder,
                                    onClick: (sortOrder: IHighchartsModelSortOrder) => {
                                        if ($scope.tab.model?.selected) $scope.tab.model.selected.sortOrder = sortOrder;
                                    },
                                }),
                                new ActionsPanelSelectItem({
                                    label: 'Limit',
                                    id: 'limit',
                                    available: limits.available,
                                    selected: limits.selected,
                                    onClick: (item: { label: string | number }) => {
                                        if ($scope.tab.model?.selected)
                                            $scope.tab.model.selected.limitBy = Number(item.label);
                                    },
                                }),
                                new ActionsPanelSecondaryItem({
                                    secondary: true,
                                    label: 'Hide Panel',
                                    icon: { type: 'icon-up-open' },
                                    onClick: ($event: Event) => {
                                        $scope.toggle.panel.open($event);
                                    },
                                }),
                                ...($scope.options.sidebar && $scope.options.groupByPropertiesInSidebar
                                    ? [
                                          new ActionsPanelButtonItem({
                                              label: 'Group By',
                                              selected: $scope.tab.model.selected.grouping.label,
                                              cssClass: 'sidebar-toggle',
                                              icon: { type: 'icon-down-open' },
                                              isActive: () =>
                                                  $scope.toggle.sidebar.isActive &&
                                                  $scope.toggle.sidebar.tab === 'properties',
                                              onClick: ($event: Event) => {
                                                  $event.preventDefault();
                                                  $event.stopImmediatePropagation();
                                                  $scope.toggle.sidebar.toggle($event, 'properties');
                                              },
                                          }),
                                      ]
                                    : []),
                            ],
                        });
                    },
                    true,
                );
            },
        };
    },
];

const ChartViewFunnelNodeConfigSchema = z.object({
    id: z.string(),
    choice: z.string().nullish().optional(),
});

const ChartPageViewMetaSchema = z.object({
    createdAt: z.number(),
    organizationId: z.string(),
    exportedByUserId: z.string(),
    type: z.literal('tab-chart'),
    version: z.number(),
});

const ChartPageViewDataSchema = z.object({
    id: z.string(),
    name: z.string(),
    filters: z.record(z.record(z.string())),
    limitBy: z.number().optional(),
    grouping: z.string().optional(),
    metric: z.string().optional(),
    sortOrder: z.number().optional(),
    timeGrouping: z.string().optional(),
    stacking: z.boolean().nullable().optional(),
    funnel: z
        .object({
            nodes: z.array(ChartViewFunnelNodeConfigSchema),
            selected: z.record(z.unknown()),
        })
        .optional(),
});

const ChartPageExportViewConfigSchema = z.object({
    meta: ChartPageViewMetaSchema,
    data: ChartPageViewDataSchema,
});

export type IChartPageExportTabViewConfig = z.infer<typeof ChartPageExportViewConfigSchema>;
type IChartPageTabConfig = z.infer<typeof ChartPageViewDataSchema>;

const resolveConfig = (
    tabConfig: IChartPageTabConfig | undefined | null,
    properties: IPropertyDefinition[],
    metrics: IMetricDefinition[],
): ChartPageView | null => {
    if (!tabConfig) {
        return null;
    }
    const {
        id: tabId,
        name,
        filters,
        sortOrder,
        grouping: propertyId,
        timeGrouping,
        metric: metricId,
        limitBy,
        stacking,
    } = tabConfig;
    const modelTimeGrouping = _.cloneDeep(TimeGroupings.find(t => t.id === timeGrouping) ?? TimeGroupings[0]);
    const id = tabId || Utils.uuid();

    const model: IHighchartsModel = {
        funnel: {
            nodes: [],
            selected: {},
        },
        selected: {
            filters: filters ?? {},
            timeGrouping: modelTimeGrouping,
            stacking: !!stacking,
            grouping: properties[0],
        },
    };

    if (typeof sortOrder === 'number') {
        model.selected.sortOrder = { id: sortOrder };
    }

    if (propertyId) {
        const property = properties.find(p => p.id === propertyId);
        if (property) {
            model.selected.grouping = property;
        }
    }

    if (_.isNumber(limitBy)) {
        model.selected.limitBy = limitBy;
    }

    const metric = metrics.find(m => m.field === metricId);

    if (metric) {
        model.selected.metric = metric;
        model.selected.metric.id = metric.field;
    }

    if (tabConfig.funnel && Array.isArray(tabConfig.funnel.nodes) && tabConfig.funnel.nodes.length > 0) {
        model.funnel ??= {};
        model.funnel.nodes = tabConfig.funnel.nodes.flatMap(node => {
            const property = properties.find(p => p.id === node.id);
            if (!property) return [];
            return [{ ...property, ...(node.choice ? { choice: node.choice } : {}) }];
        });
    }

    return new ChartPageView({
        id,
        name,
        metrics,
        groupBy: properties,
        model,
    });
};

export const normalizeTabToSave = (tab: ChartPageView): IChartPageTabConfig | null => {
    const { id, name, model: modelToSave } = tab;
    if (!modelToSave?.selected.metric) return null;

    const selectedTabView = deepStripAngularProperties(modelToSave.selected);
    const funnelNodes = modelToSave.funnel?.nodes?.map(node => deepStripAngularProperties(node)) ?? [];
    const funnel = {
        nodes: funnelNodes.map(node => ({ id: node.id, choice: node.choice })),
        selected: modelToSave.funnel?.selected ? deepStripAngularProperties(modelToSave.funnel.selected) : {},
    };

    return {
        id,
        name,
        filters: selectedTabView.filters,
        limitBy: selectedTabView.limitBy,
        grouping: selectedTabView.grouping?.id,
        metric: selectedTabView.metric.field ?? selectedTabView.metric.id,
        sortOrder: selectedTabView.sortOrder.id,
        timeGrouping: selectedTabView.timeGrouping.id,
        stacking: selectedTabView.stacking,
        funnel,
    };
};

export interface IChartStorage {
    selected: string;
    available: IChartPageTabConfig[];
}

export const ChartViewTabServiceFactory = () => [
    function ChartViewTabServiceFn() {
        class ChartViewTabService {
            protected tabsConfig: IChartStorage | undefined | null;
            protected selectedTabId: string | undefined;
            protected tabs: ChartPageView[] | undefined;
            protected HierarchyServiceInstance = HierarchyService();

            protected getStorageKey() {
                const prefix = 'chart.views.v1';
                return `${prefix}`;
            }

            protected async saveTabsToStorage(tabsConfig: IChartStorage) {
                const storageKey = this.getStorageKey();
                const api = await StorageAPI<IChartStorage>(storageKey);
                return api.put(tabsConfig);
            }

            protected async getTabsFromStorage() {
                const storageKey = this.getStorageKey();
                const api = await StorageAPI<IChartStorage>(storageKey);
                return api.get();
            }

            protected async fetchTabsConfig(): Promise<IChartStorage | null> {
                const state = await this.getTabsFromStorage();
                if (!Utils.isObject(state)) return null;
                if (!Array.isArray(state.available)) return null;

                const available = state.available.filter(Utils.isObject).map(tab => {
                    const id = typeof tab.id === 'string' ? tab.id : Utils.uuid();
                    return { ...tab, id };
                });
                if (state.available.length === 0) return null;

                const selected = (() => {
                    const id = state.selected;
                    if (!id) return available[0]?.id;
                    const tab = available.find(tab => tab.id === id) ?? available[0];
                    return tab.id;
                })();

                return { selected, available };
            }

            protected fetchTabsView(
                metrics: IMetricDefinition[],
                groupByHierarchy: IPropertyDefinition[],
                tabsConfig: IChartPageTabConfig[],
            ): ChartPageView[] {
                return _.compact(tabsConfig.map(t => resolveConfig(t, groupByHierarchy, metrics)));
            }

            async saveTabs(selectedTabId: string, newTabs: ChartPageView[]) {
                this.selectedTabId = selectedTabId;
                const newTabsConfig: IChartStorage = {
                    selected: selectedTabId,
                    available: _.compact(newTabs.map(tab => normalizeTabToSave(_.cloneDeep(tab)))),
                };

                if (!_.isEqual(newTabsConfig, this.tabsConfig)) {
                    if (this.tabs) this.tabs = newTabs;
                    this.tabsConfig = newTabsConfig;
                    void this.saveTabsToStorage(this.tabsConfig);
                }
            }

            protected async getTabsConfig() {
                this.tabsConfig ??= await this.fetchTabsConfig();
                return this.tabsConfig;
            }

            async getTabs(
                metrics: IMetricDefinition[],
                groupByHierarchy: IPropertyDefinition[],
            ): Promise<{ selectedTabId: string | undefined; tabs: ChartPageView[] }> {
                if (!this.tabs) {
                    const tabsConfig = await this.getTabsConfig();

                    if (tabsConfig && tabsConfig.available.length > 0) {
                        this.selectedTabId = tabsConfig.selected;
                        this.tabs = this.fetchTabsView(metrics, groupByHierarchy, tabsConfig.available);

                        await this.saveTabs(this.selectedTabId, this.tabs);
                    }
                }

                return _.cloneDeep({ selectedTabId: this.selectedTabId, tabs: this.tabs ?? [] });
            }

            async getData(metrics: IMetricDefinition[], groupByHierarchy: IPropertyDefinition[]) {
                return this.getTabs(metrics, groupByHierarchy).then(({ tabs, selectedTabId }) => {
                    return {
                        groupBy: groupByHierarchy,
                        metrics,
                        tabs,
                        selectedTabId,
                    };
                });
            }
        }

        return new ChartViewTabService();
    },
];

export type IChartViewTabService = AngularInjected<ReturnType<typeof ChartViewTabServiceFactory>>;
export const ChartPageTabsFactory = () => [
    '$q',
    'ChartViewTabService',
    'DataDescriptors',
    'QueryMetrics',
    function ChartViewTabFn(
        $q: angular.IQService,
        ChartViewTabService: IChartViewTabService,
        DataDescriptors: IDataDescriptorsService,
        QueryMetrics: IQueryMetrics,
    ) {
        class ChartPageTabs {
            segmentName: string;
            tabs: ChartPageView[] = [];
            selectedTab: ChartPageView | undefined;
            metrics: IMetricDefinition[] = [];
            groupBy: IPropertyDefinition[] = [];
            options: {
                enableTimeGrouping: boolean;
                groupByPropertiesInSidebar: boolean;
                sidebar: boolean;
            } = {
                enableTimeGrouping: false,
                groupByPropertiesInSidebar: false,
                sidebar: false,
            };
            constructor(options: IChartPageViewFactoryConfig) {
                this.segmentName = options.segmentName ?? '';
                void this.init();
            }

            ValidateViewConfigFile(payload: unknown, orgId: string): IChartPageTabConfig {
                try {
                    if (typeof payload === 'string') payload = JSON.parse(payload);
                } catch (error) {
                    console.error(error);
                    throw new Error('View config import error: bad json.', { cause: error });
                }

                if (!Utils.isObject(payload)) {
                    throw new Error('View config import error: must be a string or object');
                }

                const meta = ChartPageViewMetaSchema.safeParse(payload.meta);
                if (!meta.success) {
                    console.error(meta.error);
                    throw new Error('View config import error: meta field missing or invalid.');
                }

                if (meta.data.organizationId !== orgId) {
                    throw new Error('View config import error: incorrect organization.');
                }

                const parsedData = ChartPageViewDataSchema.safeParse(payload.data);
                if (!parsedData.success) {
                    console.error(parsedData.error);
                    throw new Error('Chart Page View config import error: data field missing or invalid.');
                }

                return parsedData.data;
            }

            private init() {
                return $q
                    .all([
                        QueryMetrics.fetch(),
                        fetchGroupByHierarchy(),
                        DataDescriptors.fetch(),
                        ConfigExperimentsAPI.fetch(),
                    ])
                    .then(([metrics, groupByHierarchy, descriptors, experiments]) => {
                        this.metrics = metrics;
                        this.groupBy = groupByHierarchy;
                        this.options.enableTimeGrouping = !!descriptors.calendar;
                        this.options.groupByPropertiesInSidebar = !!experiments.groupByPropertiesInSidebar;
                        this.options.sidebar = !!experiments.sidebar;

                        return $q
                            .when(ChartViewTabService.getData(this.metrics, this.groupBy))
                            .then(({ metrics, tabs, groupBy, selectedTabId }) => {
                                const viewTabs = tabs.map(storageTab => {
                                    const { id, name, model } = storageTab;
                                    const tab = new ChartPageView({
                                        segmentName: this.segmentName,
                                        id,
                                        name,
                                        metrics,
                                        groupBy,
                                        model,
                                    });

                                    return tab;
                                });

                                if (viewTabs.length > 0) {
                                    this.tabs = viewTabs;
                                    this.selectedTab = viewTabs.find(tab => tab.id === selectedTabId);
                                    return;
                                }

                                return this.addNewTab();
                            });
                    });
            }

            protected createNewTab() {
                const tab = new ChartPageView({
                    segmentName: this.segmentName,
                    metrics: this.metrics,
                    groupBy: this.groupBy,
                });
                this.selectedTab = tab;
                this.tabs.push(tab);

                return this.save();
            }

            selectTab(tabToSelectId: string) {
                if (tabToSelectId !== this.selectedTab?.id) {
                    const selectedTabIndex = this.tabs.findIndex(tab => tab.id === tabToSelectId);
                    if (selectedTabIndex > -1) {
                        const oldTab = this.tabs[selectedTabIndex];
                        if (!oldTab) return;

                        const tab = new ChartPageView({
                            segmentName: this.segmentName,
                            metrics: this.metrics,
                            groupBy: this.groupBy,
                            id: oldTab.id,
                            name: oldTab.name,
                            model: oldTab.model,
                        });

                        this.tabs[selectedTabIndex] = tab;
                        this.selectedTab = tab;

                        this.save();
                    }
                }
            }

            addNewTab() {
                return this.createNewTab();
            }

            duplicateTab(id: string) {
                const tabToDuplicate = this.tabs.find(tab => tab.id === id);

                if (tabToDuplicate?.model?.selected) {
                    const options: IChartPageViewConfig = {
                        segmentName: this.segmentName,
                        metrics: this.metrics,
                        groupBy: this.groupBy,
                    };

                    const funnelNodes =
                        tabToDuplicate.model.funnel?.nodes?.map(node => deepStripAngularProperties(node)) ?? [];
                    const funnel = {
                        nodes: funnelNodes,
                        selected: tabToDuplicate.model.funnel?.selected
                            ? deepStripAngularProperties(tabToDuplicate.model.funnel.selected)
                            : {},
                    };
                    const selected = deepStripAngularProperties(tabToDuplicate.model.selected);

                    const tab = new ChartPageView({
                        ...options,
                        id: Utils.uuid(),
                        model: {
                            funnel,
                            selected,
                        },
                        name: tabToDuplicate.name + ' (copy)',
                    });
                    this.selectedTab = tab;
                    this.tabs.push(tab);

                    this.save();
                }
            }

            createTabFromJSON(tabConfig: IChartPageTabConfig) {
                tabConfig.name = `${tabConfig.name} (shared)`;
                const view = resolveConfig(tabConfig, this.groupBy, this.metrics);

                const { name, model } = view ?? {};
                const tab = new ChartPageView({
                    segmentName: this.segmentName,
                    name,
                    metrics: this.metrics,
                    groupBy: this.groupBy,
                    model,
                });

                this.tabs.push(tab);
                this.selectTab(tab.id);
            }

            exportTab(id: string) {
                const tabToExport = this.tabs.find(tab => tab.id === id);
                if (tabToExport) {
                    const data = normalizeTabToSave(tabToExport);
                    if (!data) return;

                    return $q.when(
                        downloadFile({
                            data,
                            name: data.name,
                            type: 'tab-chart',
                            namespace: 'chart',
                            analyticsEvent: Analytics.EVENTS.USER_EXPORT_CHART_TAB,
                        }),
                    );
                }
            }

            deleteTab(id: string) {
                const tabToDeleteIndex = this.tabs.findIndex(tab => tab.id === id);

                if (tabToDeleteIndex > -1) {
                    this.tabs = Utils.Array.removeAt(this.tabs, tabToDeleteIndex);
                    if (tabToDeleteIndex === 0) {
                        if (this.tabs.length === 0) {
                            this.createNewTab();
                        } else {
                            if (this.tabs[0]) this.selectTab(this.tabs[0].id);
                        }
                        return;
                    }

                    const tabIdToSelect = this.tabs[tabToDeleteIndex - 1];
                    if (tabIdToSelect) this.selectTab(tabIdToSelect.id);
                }
            }

            reorderTabs(oldIndex: number, newIndex: number) {
                this.tabs = Utils.Array.move(this.tabs, oldIndex, newIndex);
                this.save();
            }

            updateSegmentName(segmentName: string) {
                this.segmentName = segmentName;
                this.selectedTab?.setSegmentName(this.segmentName);
            }

            save() {
                const selectedTabId = this.selectedTab ? this.selectedTab.id : this.tabs[0]?.id;
                if (selectedTabId) void ChartViewTabService.saveTabs(selectedTabId, this.tabs);
            }
        }

        return ChartPageTabs;
    },
];

export type IChartPageTabsFactory = AngularInjected<typeof ChartPageTabsFactory>;
export type IChartPageTabs = InstanceType<IChartPageTabsFactory>;

interface StoreMainPartDirectiveScope extends angular.IScope {
    toggle: {
        sidebar: undefined | SidebarToggleModel;
        panel: ToggleModel;
    };
    sidebarModel: undefined | SidebarModel;
    isSidebarDisabled: boolean;
}

export const StoreMainPartDirective = () => [
    '$timeout',
    function StoreMainPartDirectiveFn(
        $timeout: angular.ITimeoutService,
    ): angular.IDirective<StoreMainPartDirectiveScope> {
        return {
            restrict: 'E',
            scope: {
                sidebarModel: '=',
                isSidebarDisabled: '=',
                toggle: '=',
                view: '=',
                actions: '=',
            },
            replace: true,
            template: `
                <div class="main-part">
                    <sidebar ng-if="sidebarModel" toggle="toggle.sidebar" model="sidebarModel"></sidebar>
                    <div class="main-body-part">
                        <store-actions-panel
                            ng-if="view.selectedTab"
                            tab="view.selectedTab"
                            options="view.options"
                            toggle="toggle"
                        ></store-actions-panel>
                        <div class="main-body-sliding-part">
                            <store-text-row model="view.selectedTab.model" toggle="toggle.panel"></store-text-row>
                            <div class="store-chart-container card">
                                <store-highcharts
                                    ng-if="view.selectedTab"
                                    view="view.selectedTab"
                                    save="actions.save"
                                ></store-highcharts>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            link: function StoreMainPartDirectiveLink($scope, $element) {
                const getMainBodySlidingPartElement = () => {
                    const $mainBodySlidingPart = $element.find('.main-body-sliding-part');
                    if ($mainBodySlidingPart[0]) return $mainBodySlidingPart[0];
                    throw new Error('element not found: .main-body-sliding-part');
                };

                const getMainElement = () => {
                    const selector = '#view > .view.view-stores > main';
                    const element = document.querySelector<HTMLElement>(selector);
                    if (!element) throw new Error(`element not found: ${selector}`);
                    return element;
                };

                const resizeFunctions = {
                    withSidebar: () => {
                        const selector = '.dimensions-sidebar .sidebar-properties-menu .metrics-funnel-breadcrumb';
                        const $dimensionsSidebarProperties = document.querySelector<HTMLElement>(selector);
                        if (!$dimensionsSidebarProperties) {
                            if (!$scope.sidebarModel?.toggle.isActive) return;
                            throw new Error(`element not found: ${selector}`);
                        }
                        const top = $dimensionsSidebarProperties.getBoundingClientRect().top;
                        const bottom = document.documentElement.clientHeight;
                        $dimensionsSidebarProperties.style.height = `${bottom - top - 2}px`;
                        const $mainBodySlidingPart = getMainBodySlidingPartElement();
                        const mainBodySlidingPartTop = $mainBodySlidingPart.getBoundingClientRect().top;
                        $mainBodySlidingPart.style.height = `${bottom - mainBodySlidingPartTop - 20}px`;
                    },
                    withoutSidebar: (collapsed: boolean, animationEnabled = true) => {
                        const $mainBodySlidingPart = getMainBodySlidingPartElement();
                        $mainBodySlidingPart.style.position = 'absolute';
                        $mainBodySlidingPart.style.zIndex = '14';
                        const $textRowElementHeight = $element.find('.store-text-row').outerHeight() ?? 0;
                        $mainBodySlidingPart.style.transition = animationEnabled ? 'top 0.7s ease-in-out' : 'unset';

                        if (collapsed) {
                            $mainBodySlidingPart.style.top = '39px';
                            $mainBodySlidingPart.style.height = `calc(100% - 39px - ${$textRowElementHeight}px)`;
                        } else {
                            const $storeActionsPanelHeight = $element.find('.store-actions-panel').outerHeight() ?? 0;
                            const top = $storeActionsPanelHeight + 39;
                            $mainBodySlidingPart.style.top = `${top}px`;
                            $mainBodySlidingPart.style.height = `calc(100% - ${top}px - ${$textRowElementHeight}px )`;
                        }
                    },
                };

                const updateSlidingPanel = (collapsed: boolean, animationEnabled = true) => {
                    if (!$scope.sidebarModel) {
                        resizeFunctions.withoutSidebar(collapsed, animationEnabled);
                    } else {
                        resizeFunctions.withSidebar();
                    }
                };

                let resizeActionsPanelObserver: undefined | ResizeObserver;
                const initObserver = () => {
                    resizeActionsPanelObserver = new ResizeObserver(() =>
                        updateSlidingPanel($scope.toggle.panel.isActive, false),
                    );
                    if ($element[0]) resizeActionsPanelObserver.observe($element[0]);
                    resizeActionsPanelObserver.observe(getMainElement());
                };

                void $timeout(() => {
                    updateSlidingPanel(false, false);
                    initObserver();
                }, 200);

                if ($scope.isSidebarDisabled) {
                    $scope.$watch('toggle.panel.isOpen', (isOpen: undefined | boolean, oldState) => {
                        if (isOpen === undefined) return;
                        const animationEnabled = isOpen !== oldState;
                        resizeFunctions.withoutSidebar(isOpen, animationEnabled);
                    });
                }

                $scope.$on('$destroy', () => {
                    resizeActionsPanelObserver?.disconnect();
                });
            },
        };
    },
];

export const StoreControllerFactory = () => [
    '$q',
    '$scope',
    '$rootScope',
    'ChartPageTabs',
    function StoreController(
        $q: angular.IQService,
        $scope: angular.IScope &
            DragAndDropExternalAPI & {
                experiments: ConfigExperimentsAPI.ConfigPageExperiments;
                query: IQuery;
                organizationId: string;
                label: string;
                view?: IChartPageTabs;
                toggle: {
                    sidebar: undefined | SidebarToggleModel;
                    panel: ToggleModel;
                };
                sidebarModel: SidebarModel | undefined;
                hideActionsPanel: boolean;
                openTabImportPopup: ($event: Event) => boolean;
                isSidebarDisabled: boolean;
                toggleSidebar: (event: MouseEvent) => void;
                pebblesGroupByModel: PropertiesItemsModel;
                actionsPanelModel: ActionsPanelModel;
                actions: {
                    removed: (id: string) => void;
                    duplicated: (id: string) => void;
                    dragged: (oldIndex: number, newIndex: number) => void;
                    addNewTab: () => void;
                    selectTab: (value: string) => void;
                    exportTab: undefined | ((id: string) => void);
                    importTab: undefined | (($event: Event) => void);
                    save: () => void;
                };
            },
        $rootScope: DashboardRootScope,
        ChartPageTabsFactory: IChartPageTabsFactory,
    ) {
        $scope.query = {};
        $scope.toggle = {
            sidebar: undefined,
            panel: new ToggleModel(),
        };
        $scope.isSidebarDisabled = true;
        $scope.toggleSidebar = (event: MouseEvent) => $scope.toggle.sidebar?.toggle(event, 'properties');

        let segmentName = $rootScope.smartGroupsModel?.view?.groups.view.selected.model.name ?? '';
        let dnd: ReturnType<DragAndDropExternalAPI['fillDragAndDropZoneExternalAPI']> | undefined;

        const init = () => {
            void $q.all([getOrganization(), ConfigExperimentsAPI.fetch()]).then(([org, experiments]) => {
                $scope.experiments = experiments;
                $scope.organizationId = org;
                const { sidebar } = $scope.experiments;
                $scope.isSidebarDisabled = !sidebar;
                $scope.view = new ChartPageTabsFactory({ segmentName });
                $scope.actions.exportTab = (id: string) => void $scope.view?.exportTab(id);
                $scope.actions.importTab = ($event: Event) => void $scope.openTabImportPopup($event);

                dnd ??= $scope.fillDragAndDropZoneExternalAPI({
                    onError: (error: unknown) => {
                        Analytics.track(Analytics.EVENTS.USER_IMPORT_GRID_TAB_FAILED, { error });
                    },
                    onFile: (file: unknown) => {
                        if (!$scope.view) return;
                        const newView = $scope.view.ValidateViewConfigFile(file, $scope.organizationId);
                        $scope.view.createTabFromJSON(newView);
                        Analytics.track(Analytics.EVENTS.USER_IMPORT_GRID_TAB, { view: newView });
                    },
                });
            });
        };

        $scope.actions = {
            removed: (id: string) => {
                const removeTab = window.confirm(
                    `Are you sure you want to delete the View - "${
                        $scope.view?.selectedTab?.name ?? ''
                    }" ?\nThis action cannot be un-done.`,
                );
                if (removeTab) $scope.view?.deleteTab(id);
            },
            duplicated: (id: string) => $scope.view?.duplicateTab(id),
            dragged: (oldIndex: number, newIndex: number) => $scope.view?.reorderTabs(oldIndex, newIndex),
            addNewTab: () => {
                $scope.view?.addNewTab();
                Analytics.track(Analytics.EVENTS.USER_CREATE_VIEW_CHART);
            },
            selectTab: (id: string) => {
                $scope.view?.selectTab(id);
            },
            save: () => $scope.view?.save(),
            exportTab: undefined,
            importTab: undefined,
        };

        init();

        $scope.$watch('view.selectedTab.model.available', () => {
            $scope.sidebarModel = (() => {
                if (!$scope.view?.selectedTab?.model?.available || !$scope.experiments.sidebar) return;
                const { available, selected } = $scope.view.selectedTab.model;

                const selectedProperties = Array.isArray(selected.grouping) ? selected.grouping : [selected.grouping];
                return new SidebarModel({
                    properties: {
                        available: available.grouping,
                        selected: selectedProperties,
                        selectProperty: property => {
                            if ($scope.view?.selectedTab?.model && property[0]) {
                                $scope.view.selectedTab.model.selected.grouping = property[0];
                            }
                        },
                    },
                    options: {
                        hideTabs: true,
                    },
                    toggle: (() => {
                        return $scope.sidebarModel?.toggle ? $scope.sidebarModel.toggle : undefined;
                    })(),
                });
            })();

            $scope.toggle.sidebar = $scope.sidebarModel?.toggle;
        });

        const unWatchSegmentName = $rootScope.$watch(
            'smartGroupsModel.view.groups.view.selected.model',
            (selectedModel?: SmartGroupViewModel) => {
                segmentName = selectedModel?.name ?? '';
                $scope.view?.updateSegmentName(segmentName);
            },
        );

        const unWatchQueryRefresh = $rootScope.$on('query.refresh', () => init());

        const buildLimit = (tab: ChartPageView) => {
            const limitBy = 20;
            const limits = (tab.model?.available.limitBy ?? [limitBy]).map(limitBy => ({ label: limitBy }));
            const isBarChart = Boolean($scope.view?.selectedTab?.model?.selected.timeGrouping.chartType === 'barchart');
            const available = limits.filter(x => isBarChart || x.label <= 50);
            const selected = available.find(limit => limit.label === tab.model?.selected.limitBy) ?? {
                label: limitBy,
            };

            return { available, selected };
        };

        $scope.$watch(
            'view.selectedTab.model.selected',
            (selectedModel: IHighchartsSelectedModel | undefined) => {
                if (!selectedModel || !$scope.view?.selectedTab?.model?.available) return;

                if (!$scope.view.options.sidebar) {
                    const { properties, grouping } = $scope.view.selectedTab.model.selected;
                    $scope.pebblesGroupByModel = {
                        label: 'Group By',
                        available: properties,
                        selected: grouping,
                        onClick: properties => {
                            if ($scope.view?.selectedTab?.model && properties[0]) {
                                $scope.view.selectedTab.model.selected.grouping = properties[0];
                            }
                        },
                    };
                }

                const limits = buildLimit($scope.view.selectedTab);
                $scope.actionsPanelModel = new ActionsPanelModel({
                    items: [
                        ...($scope.view.options.enableTimeGrouping
                            ? [
                                  new ActionsPanelSelectItem({
                                      label: 'Time Grouping',
                                      available: $scope.view.selectedTab.model.available.timeGrouping,
                                      selected: $scope.view.selectedTab.model.selected.timeGrouping,
                                      onClick: item => {
                                          if ($scope.view?.selectedTab?.model)
                                              $scope.view.selectedTab.model.updateTimeGrouping(item);
                                          // $scope.view.selectedTab.model.selected.timeGrouping = item;
                                      },
                                  }),
                              ]
                            : []),
                        new ActionsPanelSelectItem<IHighchartsSelectedModel['metric']>({
                            label: (() => {
                                if ($scope.view.selectedTab.model.selected.timeGrouping.chartType === 'barchart') {
                                    return 'Sort By';
                                }
                                if ($scope.view.selectedTab.model.selected.timeGrouping.chartType === 'timeseries') {
                                    return 'Metric';
                                }
                                return '';
                            })(),
                            available: $scope.view.selectedTab.model.available.metric,
                            selected: $scope.view.selectedTab.model.selected.metric,
                            onClick: metric => {
                                if ($scope.view?.selectedTab?.model?.selected)
                                    $scope.view.selectedTab.model.selected.metric = metric;
                            },
                        }),
                        new ActionsPanelSelectItem({
                            available: $scope.view?.selectedTab.model.available.sortOrder,
                            label: 'Sort Order',
                            selected: $scope.view?.selectedTab.model.selected.sortOrder,
                            onClick: (sortOrder: IHighchartsModelSortOrder) => {
                                if ($scope.view?.selectedTab?.model?.selected)
                                    $scope.view.selectedTab.model.selected.sortOrder = sortOrder;
                            },
                        }),
                        new ActionsPanelSelectItem({
                            label: 'Limit',
                            id: 'limit',
                            available: limits.available,
                            selected: limits.selected,
                            onClick: (item: { label: string | number }) => {
                                if ($scope.view?.selectedTab?.model?.selected)
                                    $scope.view.selectedTab.model.selected.limitBy = Number(item.label);
                            },
                        }),

                        ...($scope.view.options.sidebar
                            ? [
                                  new ActionsPanelButtonItem({
                                      label: 'Group By',
                                      selected: $scope.view.selectedTab.model.selected.grouping.label,
                                      cssClass: 'sidebar-toggle',
                                      icon: { type: 'icon-down-open' },
                                      isActive: () =>
                                          !!$scope.toggle.sidebar &&
                                          $scope.toggle.sidebar.isActive &&
                                          $scope.toggle.sidebar.tab === 'properties',
                                      onClick: ($event: Event) => {
                                          $event.preventDefault();
                                          $event.stopImmediatePropagation();
                                          $scope.toggle.sidebar?.toggle($event, 'properties');
                                      },
                                  }),
                              ]
                            : []),
                    ],
                });
            },
            true,
        );

        $scope.openTabImportPopup = ($event: Event) => {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            if (!dnd) throw new Error('[grid-page] Drag and Drop zone not initialized');
            dnd.openUploadPopup();
            return true;
        };

        $scope.$on('$destroy', () => {
            unWatchSegmentName();
            unWatchQueryRefresh();
        });
    },
];
