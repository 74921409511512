import _ from 'lodash';
import type { IMetricDefinition } from '../lib/types';
import { DOMParser, purifyURL } from '../lib/dom/html';

export interface IGridItemCellRenderImage {
    klass: string;
    value: string | null;
}

export interface IGridItemCellRenderGroupBy {
    klass: string;
    value: string;
}

const createHTMLElement = (tag: string, klass: string | string[], content: string) => {
    const elem = document.createElement(tag);
    klass = Array.isArray(klass) ? klass : [klass];
    klass.forEach(k => k && elem.classList.add(k));
    elem.innerHTML = content;
    return elem;
};

export const renderItemMetrics = (
    $filter: angular.IFilterService,
    itemMetrics: (IMetricDefinition | undefined)[] | undefined | null,
    getter: (data: IMetricDefinition) => string | number | undefined,
): string => {
    if (!itemMetrics || itemMetrics.length < 1) return '';

    const groupedMetrics = _.groupBy(itemMetrics, x => x?.headerGroup ?? '');
    const orderedHeaderGroups = itemMetrics.reduce<string[]>((acc, metric) => {
        if (!metric || acc.includes(metric.headerGroup)) return acc;
        return [...acc, metric.headerGroup];
    }, []);

    const result = orderedHeaderGroups.map(headerGroup => {
        const metrics = groupedMetrics[headerGroup] ?? [];
        const isBare = metrics.length === 1;

        const itemMetricGroupElement = createHTMLElement('span', ['item-metric-group', isBare ? 'bare' : ''], '');
        const itemMetricHeaderGroupElement = createHTMLElement('span', ['item-metric-header-group'], headerGroup);
        itemMetricGroupElement.appendChild(itemMetricHeaderGroupElement);

        const metricsLinesHTML = metrics.reduce<HTMLElement[]>((acc, metric) => {
            if (!metric) return acc;

            let value = getter(metric);
            value = typeof value === 'number' ? value.toString() : value;
            value = typeof value === 'string' && value.length > 0 ? value : undefined;

            if (metric.cellFilter && typeof value === 'string') {
                const [filterName, ...filterArgs] = metric.cellFilter.split(':');
                if (filterName) {
                    try {
                        value = $filter(filterName)(value, ...filterArgs);
                    } catch (err) {
                        console.warn('[Grid Page] - Unsupported filter', filterName, err);
                    }
                }
            }

            const headerName = isBare && metric.headerName === 'TY' ? '' : metric.headerName;
            const hasPercentageCellClass = (metric.cellClass ?? '').includes('percent');

            let valueClass = '';
            if (hasPercentageCellClass) {
                let rawValue = getter(metric);
                if (typeof rawValue === 'number') {
                    rawValue = metric.cellClass === 'percent-inverted' ? rawValue * -1 : rawValue;
                    if (rawValue > 0) valueClass = 'percent-positive';
                    if (rawValue < 0) valueClass = 'percent-negative';
                }
            }

            const valueHTML =
                !value || value.length === 0
                    ? createHTMLElement('span', ['item-metric-value', 'blank'], '')
                    : createHTMLElement('span', ['item-metric-value', valueClass], value);

            const headerNameHTML = !headerName
                ? ''
                : createHTMLElement('span', ['item-metric-header-name'], headerName);

            const elem = document.createElement('span');
            elem.classList.add('item-metric');
            [headerNameHTML, valueHTML].forEach(el => {
                typeof el === 'string' ? elem.append(el) : elem.appendChild(el);
            });
            acc.push(elem);
            return acc;
        }, []);

        metricsLinesHTML.forEach(metricLine => itemMetricGroupElement.append(metricLine));
        return itemMetricGroupElement.outerHTML;
    });

    return result.join('');
};

export const getItemExtraPropertiesHTML = (
    selectedExtraProperties: { column?: string; id: string }[],
    item: Record<string, string | number | undefined>,
) => {
    const itemExtraProperties = selectedExtraProperties.flatMap(descriptor => {
        const column = descriptor.column ?? descriptor.id.replace(/^.*?\./, '');
        const columnKey = `item_${column}`;
        const klass = ['item-property', `item-property-${column.replace(/_/g, '-')}`];
        const value = String(item[columnKey] ?? '');
        return [{ klass, value }];
    });
    if (selectedExtraProperties.length === 0) return null;
    const container = document.createElement('div');
    container.classList.add('item-extra-properties');
    for (const { klass, value } of itemExtraProperties) {
        const span = document.createElement('span');
        klass.forEach(k => k && span.classList.add(k));
        span.innerText = value;
        container.appendChild(span);
    }
    return container.innerHTML;
};

export const getMetricsHTML = (
    $filter: angular.IFilterService,
    metrics: (IMetricDefinition | undefined)[] | null | undefined,
    item: Record<string, string | number | undefined>,
) => {
    return metrics ? renderItemMetrics($filter, metrics, (metric: IMetricDefinition) => item[metric.field]) : '';
};

export interface ItemCellRendererInitParams {
    item?: Record<string, string | number | undefined> | undefined;
    label?: string | number | undefined;
    imagesEnabled: boolean;
    availableMetrics?: Record<string, IMetricDefinition> | undefined;
    selectedMetrics?: string[] | undefined;
    selectedExtraProperties?: { column?: string; id: string }[] | undefined;
}

export class ItemCellRenderer {
    eGuiElement: HTMLDivElement | undefined;
    eGuiImageElement: HTMLDivElement | undefined;
    eGuiMetricsElement: HTMLDivElement | undefined;
    eGuiPropertiesElement: HTMLDivElement | undefined;

    $filter: angular.IFilterService;
    item: Record<string, string | number | undefined> | undefined;
    groupByConfig: IGridItemCellRenderGroupBy = { klass: 'item-property-value', value: '' };
    metrics: (IMetricDefinition | undefined)[] = [];
    availableMetrics: Record<string, IMetricDefinition> = {};
    imageConfig: IGridItemCellRenderImage | null = null;
    itemExtraPropertiesHTML: string | null = null;
    metricsHTML: string | null = null;
    imagesEnabled = true;

    constructor($filter: angular.IFilterService) {
        this.$filter = $filter;
    }

    createImageElement(imageConfig: IGridItemCellRenderImage) {
        const eGuiImageElement = document.createElement('div');
        eGuiImageElement.classList.add(imageConfig.klass);
        if (imageConfig.value) {
            eGuiImageElement.style.backgroundImage = `url('${purifyURL(imageConfig.value)}')`;
        } else {
            eGuiImageElement.classList.add('blank');
        }
        return eGuiImageElement;
    }

    createPropertiesElement(itemExtraPropertiesHTML: string) {
        const eGuiPropertiesElement = document.createElement('div');
        eGuiPropertiesElement.classList.add('item-extra-properties');
        eGuiPropertiesElement.insertAdjacentHTML('beforeend', itemExtraPropertiesHTML);
        return eGuiPropertiesElement;
    }

    createMetricElement(metricsHTML: string) {
        const eGuiMetricsElement = document.createElement('div');
        eGuiMetricsElement.classList.add('item-metrics');
        if (metricsHTML === '') {
            eGuiMetricsElement.classList.add('hide');
        }
        eGuiMetricsElement.insertAdjacentHTML('beforeend', metricsHTML);
        return eGuiMetricsElement;
    }

    createGroupByDivElement(groupByConfig: IGridItemCellRenderGroupBy) {
        const eGuiGroupByLabelElement = document.createElement('div');
        eGuiGroupByLabelElement.classList.add(groupByConfig.klass);
        eGuiGroupByLabelElement.appendChild(document.createTextNode(groupByConfig.value));
        return eGuiGroupByLabelElement;
    }

    createGroupByConfig(property: string | number | undefined) {
        const value = typeof property === 'number' ? property.toString() : typeof property === 'string' ? property : '';
        return { klass: 'property-value', value };
    }

    createImageConfig(item: Record<string, unknown>): IGridItemCellRenderImage {
        const value = typeof item.item_image === 'string' ? item.item_image : null;
        return { klass: 'item-image', value };
    }

    resetElements() {
        this.eGuiElement = undefined;
        this.eGuiImageElement = undefined;
        this.eGuiMetricsElement = undefined;
        this.eGuiPropertiesElement = undefined;
    }

    init(params: ItemCellRendererInitParams) {
        this.resetElements();
        const {
            item,
            imagesEnabled,
            availableMetrics,
            label,
            selectedMetrics = [],
            selectedExtraProperties = [],
        } = params;
        this.item = item;
        this.availableMetrics = availableMetrics ?? {};

        if (item) {
            this.imagesEnabled = imagesEnabled;
            this.imageConfig = this.createImageConfig(item);
            this.groupByConfig = this.createGroupByConfig(label);

            this.metrics = selectedMetrics.map((metric: string) => this.availableMetrics[metric]);
            this.itemExtraPropertiesHTML = getItemExtraPropertiesHTML(selectedExtraProperties, item);
            this.metricsHTML = getMetricsHTML(this.$filter, this.metrics, item);
        }

        // Build HTML
        const eGuiElement = document.createElement('div');
        eGuiElement.classList.add('item-grid-cell-value');

        // Add IMAGE
        if (this.imageConfig) {
            this.eGuiImageElement = this.createImageElement(this.imageConfig);
            eGuiElement.appendChild(this.eGuiImageElement);
            if (!this.imagesEnabled) {
                this.eGuiImageElement.classList.remove(this.imageConfig.klass);
                this.eGuiImageElement.style.backgroundImage = 'none';
            }
        }

        const eGuiPropertiesAndMetricsListElement = document.createElement('div');
        eGuiPropertiesAndMetricsListElement.classList.add('item-info');

        const eGuiGroupByLabelElement = this.createGroupByDivElement(this.groupByConfig);
        eGuiPropertiesAndMetricsListElement.appendChild(eGuiGroupByLabelElement);

        // Add Item Extra Properties
        if (this.itemExtraPropertiesHTML) {
            this.eGuiPropertiesElement = this.createPropertiesElement(this.itemExtraPropertiesHTML);
            eGuiPropertiesAndMetricsListElement.appendChild(this.eGuiPropertiesElement);
        }

        this.eGuiMetricsElement = this.createMetricElement(this.metricsHTML ?? '');
        eGuiPropertiesAndMetricsListElement.appendChild(this.eGuiMetricsElement);

        eGuiElement.appendChild(eGuiPropertiesAndMetricsListElement);
        this.eGuiElement = eGuiElement;
    }

    update(metrics: string[] = []) {
        this.metrics = metrics.map((metric: string) => this.availableMetrics[metric]);
        this.metricsHTML = this.item ? getMetricsHTML(this.$filter, this.metrics, this.item) : '';
        const nodes = new DOMParser().parseFromString(this.metricsHTML, 'text/html').body.childNodes;

        if (this.metricsHTML === '' && !this.eGuiMetricsElement?.classList.contains('hide')) {
            this.eGuiMetricsElement?.classList.add('hide');
        } else {
            if (this.metricsHTML !== '' && this.eGuiMetricsElement?.classList.contains('hide')) {
                this.eGuiMetricsElement.classList.remove('hide');
            }
        }

        this.eGuiMetricsElement?.replaceChildren(...Array.from(nodes));
    }

    getGui() {
        return this.eGuiElement ?? document.createElement('div');
    }
}

export interface InfoItemCellRendererInitParams {
    item?: Record<string, string | number | undefined>;
    label?: string | number | undefined;
    availableMetrics: Record<string, IMetricDefinition>;
    selectedMetrics: string[];
}

export class InfoItemCellRenderer {
    eGuiElement: HTMLDivElement | undefined;
    eGuiMetricsElement: HTMLDivElement | undefined;
    eGuiGroupByElement: HTMLDivElement | undefined;

    metrics: (IMetricDefinition | undefined)[] = [];
    availableMetrics: Record<string, IMetricDefinition> = {};
    metricsHTML = '';
    $filter: angular.IFilterService;
    item: Record<string, string | number | undefined> | undefined;
    groupByConfig: IGridItemCellRenderGroupBy = { klass: 'item-property-value', value: '' };
    imageConfig: IGridItemCellRenderImage | null = null;
    itemExtraPropertiesHTML: string | null = null;
    imagesEnabled = true;

    constructor($filter: angular.IFilterService) {
        this.$filter = $filter;
    }

    createMetricElement(metricsHTML: string) {
        const eGuiMetricsElement = document.createElement('div');
        eGuiMetricsElement.classList.add('item-metrics');
        eGuiMetricsElement.insertAdjacentHTML('beforeend', metricsHTML);
        return eGuiMetricsElement;
    }

    createGroupByConfig(property: string | number | undefined) {
        const value = typeof property === 'number' ? property.toString() : typeof property === 'string' ? property : '';
        return { klass: 'property-value', value };
    }

    resetElements() {
        this.eGuiElement = undefined;
        this.eGuiMetricsElement = undefined;
        this.eGuiGroupByElement = undefined;
    }

    init(params: InfoItemCellRendererInitParams) {
        this.resetElements();
        const { item, availableMetrics, label, selectedMetrics = [] } = params;
        this.item = item;
        this.availableMetrics = availableMetrics;

        this.groupByConfig = this.createGroupByConfig(label);
        this.metrics = selectedMetrics.map(metric => availableMetrics[metric]);
        this.metricsHTML = renderItemMetrics(this.$filter, this.metrics, (metric: IMetricDefinition) =>
            this.item ? this.item[metric.field] : '',
        );
        this.eGuiMetricsElement = this.createMetricElement(this.metricsHTML);

        const groupByLabelElement = (() => {
            const div = document.createElement('div');
            div.classList.add(this.groupByConfig.klass);
            const span = document.createElement('span');
            span.innerText = this.groupByConfig.value;
            div.appendChild(span);
            return div;
        })();

        const itemInfoElement = (() => {
            const div = document.createElement('div');
            div.classList.add('item-info');
            return div;
        })();

        itemInfoElement.insertAdjacentElement('beforeend', this.eGuiMetricsElement);

        this.eGuiElement = document.createElement('div');
        this.eGuiElement.classList.add('item-grid-cell-total');
        this.eGuiElement.insertAdjacentElement('beforeend', groupByLabelElement);
        this.eGuiElement.insertAdjacentElement('beforeend', itemInfoElement);
    }

    getGui() {
        return this.eGuiElement ?? document.createElement('div');
    }
}
